import { EllipsisSpan, Paging, Table, usePaging, useUpdate, Wrapper, useAsync } from '@maxtropy/components';
import { Button, Checkbox, Form, Input, Modal, Select, Space } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Filter from '@/shared/components/Filter';
import { OuStatus, OuStatusDisplay } from '@/shared/const';
import { OperatorDisplay } from '@/shared/types';
import { getRoot } from '../../../api/customer';
import {
  ProductionUnitListProps,
  queryOuTypeList,
  queryProductionUnitList,
  QueryProductionUnitListRequest,
  updateOuState,
} from '../../../api/ou';
import { PermissionsType } from '../../../common/permissionsConst';
import { useQuery } from '../../../utils/utils';
import { useMgmtPermissions } from '@/components/Layout/hooks';

const routes = [{ name: 'Iot配置' }, { name: '生产运营单元' }, { name: '单元管理' }];

type SearchParams = Omit<QueryProductionUnitListRequest, 'page' | 'size'>;

const ProductionUitList: React.FC = () => {
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const permission = useMgmtPermissions();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const nameOrSerialNum = urlSearchParams.get('nameOrSerialNum') || undefined;
  const tenantMcid = urlSearchParams.get('tenantMcid') || undefined;
  const ifCancel = !!urlSearchParams.get('ifCancel');
  const [searchParams, setSearchParams] = useState<SearchParams>({
    nameOrSerialNum,
    tenantMcid,
    ifCancel,
  });
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [invalidForm] = Form.useForm();

  const tenants = useAsync(
    useCallback(() => getRoot({ check: true }), []),
    []
  );
  const types = useAsync(queryOuTypeList, []);

  const [update, forceUpdate] = useUpdate();
  const [modalApi, modalContextHolder] = Modal.useModal();

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        queryProductionUnitList({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) {
            setTotalCount(res.total);
          }
          return res.list;
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pageOffset, pageSize, searchParams, setTotalCount, update]
    )
  );

  const columns: ColumnType<ProductionUnitListProps>[] = [
    {
      title: '编号',
      dataIndex: 'serialNumber',
      width: 200,
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v} />,
    },
    {
      title: '名称',
      dataIndex: 'name',
      width: 320,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    ...(Array.isArray(types) && types.length > 1
      ? [
          {
            title: '类型',
            width: 100,
            dataIndex: 'ouTypeName',
            ellipsis: { showTitle: true },
            render: (v: string) => <EllipsisSpan value={v} />,
          },
        ]
      : []),
    {
      title: '设备数量',
      dataIndex: 'deviceNum',
      width: 100,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? v : '0'} />,
    },
    {
      title: '状态',
      width: 100,
      dataIndex: 'status',
      ellipsis: { showTitle: true },
      render: (v: OuStatus) => <EllipsisSpan value={OuStatusDisplay[v]} />,
    },
    {
      title: '所属租户',
      width: 160,
      dataIndex: 'tenantName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所属组织',
      width: 160,
      dataIndex: 'customerName',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={String(v)} />,
    },
    {
      title: '最后操作时间',
      width: 200,
      dataIndex: 'updateTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
    },
    {
      title: '最后操作人',
      width: 200,
      ellipsis: { showTitle: true },
      render: (_: number, record) => (
        <EllipsisSpan value={`${record.updateByUsername}(${OperatorDisplay[record.updateSource]})`} />
      ),
    },
  ];

  const openInvalid = (id: number) => {
    modalApi.confirm({
      icon: null,
      title: null,
      content: (
        <>
          <Form form={invalidForm} layout="vertical">
            <Form.Item label="请输入操作密码" name="password" rules={[{ required: true, message: '请输入操作密码' }]}>
              <Input.Password autoComplete="new-password" placeholder="请输入操作密码" />
            </Form.Item>
          </Form>
        </>
      ),
      onOk: () => {
        return invalidForm.validateFields().then(value => {
          updateOuState(id, {
            state: OuStatus.DISABLE,
            password: value.password,
          }).then(() => {
            modalApi.warning({
              title: '作废成功！',
            });
            forceUpdate();
          });
        });
      },
      afterClose: () => {
        invalidForm.resetFields();
      },
    });
  };

  const render = (record: ProductionUnitListProps) => (
    <Space size={16} className="action-buttons">
      {record.status !== OuStatus.DISABLE && (
        <>
          {(permission ?? []).includes(PermissionsType.B_ABOLISH_OU) && (
            <>
              <Button type="link" onClick={() => openInvalid(record.id)}>
                作废
              </Button>
            </>
          )}
          <Button type="link">
            <Link to={`/device/ou/production-unit/edit/${record.id}`}>编辑</Link>
          </Button>
          <Button type="link">
            <Link to={`/device/ou/production-unit/${record.id}/device`}>设备管理</Link>
          </Button>
        </>
      )}
      <Button type="link">
        <Link to={`/device/ou/production-unit/detail/${record.id}`}>查看</Link>
      </Button>
      {record.homePage && (
        <Button onClick={() => window.open(record.homePage, '__blank')} type="link">
          首页
        </Button>
      )}
    </Space>
  );

  useEffect(() => {
    form.setFieldsValue({ nameOrSerialNum, tenantMcid, ifCancel });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (val: any) => {
    const params = {
      nameOrSerialNum: val.nameOrSerialNum ? val.nameOrSerialNum : undefined,
      tenantMcid: val.tenantMcid,
      ifCancel: !!val.ifCancel,
    };
    setSearchParams(params);
    setPageOffset(1);
    const paramsObj = {
      nameOrSerialNum: val.nameOrSerialNum ? val.nameOrSerialNum : undefined,
      tenantMcid: val.tenantMcid,
      ifCancel: !!val.ifCancel,
    };
    const paramsMap = new Map<string, string>();
    Object.entries(paramsObj).forEach(([key, value]) => {
      value && paramsMap.set(key, value);
    });
    navigate(`?${qs.stringify(Object.fromEntries(paramsMap))}`);
  };

  const onReset = () => {
    const params: SearchParams = {
      nameOrSerialNum: undefined,
      tenantMcid: undefined,
      ifCancel: false,
    };
    setSearchParams(params);
    setPageOffset(1);
    navigate(`?`);
  };

  const filters = (
    <Filter
      form={form}
      onFinish={onFinish}
      onReset={onReset}
      collapseItems={
        <Form.Item name="ifCancel" valuePropName="checked" style={{ marginLeft: 20 }}>
          <Checkbox>显示已作废数据</Checkbox>
        </Form.Item>
      }
    >
      <Space size={20}>
        <Form.Item name="nameOrSerialNum" label="编号/名称">
          <Input placeholder={'请输入编号或名称查询'} />
        </Form.Item>
        <Form.Item name="tenantMcid" label="所属租户">
          <Select
            style={{ width: 180 }}
            placeholder="请选择"
            options={tenants.map(item => ({
              label: item.name,
              value: item.mcid,
            }))}
            optionFilterProp="label"
            showSearch
            allowClear
          />
        </Form.Item>
      </Space>
    </Filter>
  );

  return (
    <Wrapper routes={routes} filters={filters}>
      <Link to={`/device/ou/production-unit/new`}>
        {(permission ?? []).includes(PermissionsType.B_CREATE_OU) && (
          <Button type="primary" style={{ marginBottom: 15 }}>
            +新建生产单元
          </Button>
        )}
      </Link>
      <Table
        sticky
        rowKey="id"
        dataSource={data}
        loading={isLoading}
        scroll={{ x: 1900 }}
        columns={[...columns, { title: '操作', width: 260, fixed: 'right', render }]}
      />
      <Paging pagingInfo={pagingInfo} />
      {modalContextHolder}
    </Wrapper>
  );
};

export default ProductionUitList;
