import React, { FC, useCallback, useContext, useMemo } from 'react';
import { ColumnsType } from 'antd/es/table';
import { DriveType, ActionType } from '../../../../types';
import { useQuery } from '../../../../utils/utils';
import { getDataProperties } from '../../../../api/options';
import ModbusPoint, { EdgeDeviceTemplatePoint as EdgeDeviceTemplateModbusPoint } from './modbusPoint';
import DLT645Point, { EdgeDeviceTemplatePoint as EdgeDeviceTemplateDLT645Point } from './dlt645Point';
import IEC104Point, { EdgeDeviceTemplatePoint as EdgeDeviceTemplateIEC104Point } from './IEC104Point';
import { EdgeDeviceTemplatePoint } from '../../../EdgeDevicePointInfo';
import { PointContext } from '../../../EdgeDevicePointInfo/contextTypes';
import S7Point from './S7Point';


interface PointProps {
  editColumns?: ColumnsType<EdgeDeviceTemplatePoint>;
  onUpdate?: (values: any) => any;
  onAddIdentifier?: (values: any) => void;
  alterMode?: boolean;
}

const Point: FC<PointProps> = props => {
  const { dataSource, row, info } = useContext(PointContext);

  const driveType = info?.driveType;

  const { data: dataPropertiesAll } = useQuery(
    useCallback(
      () =>
        info?.deviceTypeId
          ? getDataProperties(info.iotProtocol, info.deviceTypeId, info.physicalModelId)
          : Promise.resolve([]),
      [info?.iotProtocol, info?.deviceTypeId, info?.physicalModelId]
    )
  );

  const usedProperties = useMemo(
    () =>
      dataSource
        ?.filter(item => item.actionType !== ActionType.DELETE && item.dataPropertyId !== row?.dataPropertyId)
        .map(item => item.dataPropertyId) || [],
    [dataSource, row]
  );

  const usedIdentifier = useMemo(
    () =>
      (dataSource
        ?.filter(item => item.actionType !== ActionType.DELETE && item.identifier !== row?.identifier)
        .map(item => item.identifier)
        .filter(Boolean) as string[]) || [],
    [dataSource, row]
  );

  const renderPoint = () => {
    if (driveType === DriveType.MODBUS_RTU || driveType === DriveType.MODBUS_TCP) {
      return (
        <ModbusPoint
          {...props}
          editColumns={props.editColumns as ColumnsType<EdgeDeviceTemplateModbusPoint>}
          dataPropertiesAll={dataPropertiesAll}
          usedProperties={usedProperties}
          usedIdentifier={usedIdentifier}
        />
      );
    } else if (driveType === DriveType.DLT645_1997 || driveType === DriveType.DLT645_2007) {
      return (
        <DLT645Point
          {...props}
          editColumns={props.editColumns as ColumnsType<EdgeDeviceTemplateDLT645Point>}
          dataPropertiesAll={dataPropertiesAll}
          usedProperties={usedProperties}
          usedIdentifier={usedIdentifier}
        />
      );
    } else if (driveType === DriveType.IEC104) {
      return (
        <IEC104Point
          {...props}
          editColumns={props.editColumns as ColumnsType<EdgeDeviceTemplateIEC104Point>}
          dataPropertiesAll={dataPropertiesAll}
          usedProperties={usedProperties}
          usedIdentifier={usedIdentifier}
        />
      );
    } else if (driveType === DriveType.S7) {
      return (
        <S7Point
          {...props}
          editColumns={props.editColumns as ColumnsType<EdgeDeviceTemplateModbusPoint>}
          dataPropertiesAll={dataPropertiesAll}
          usedProperties={usedProperties}
          usedIdentifier={usedIdentifier}
        />
      );
    }
  };

  return <div>{renderPoint()}</div>;
};

export default Point;
