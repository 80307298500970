import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Form, Select, Cascader, DatePicker, Spin, message } from 'antd';
import { useAsyncEffect, useUpdateEffect } from 'ahooks';
import { apiCustomerGetRootGet, apiDataPropertyListByDeviceIdGet } from '@maxtropy/device-mock-apis';
import { transformDataForCascader } from './utils';
import { CategoryTree } from './types';
import styles from './index.module.scss';
import { getDeviceTypeData } from '@/api/attribute';
import { getDeviceList } from '@/api/mockDevice';
import { debounce } from 'lodash-es';
import { Device } from '@/api/entities';
import { Input, Modal } from '@maxtropy/components';
import dayjs from 'dayjs';
import { apiV2ValueDelDelValuePost, V2ValueDelDelValuePostRequest } from '@maxtropy/tody-mgmt-apis-v2';

export type RequestWithoutValidCode = Omit<V2ValueDelDelValuePostRequest, 'operationPassword'>;

type Options = {
  label?: string;
  value?: string | number;
};

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

interface Props {
  setOpen: (v: boolean) => void;
  onReset: () => void;
}

const DeleteModal: React.FC<Props> = ({ setOpen, onReset }) => {
  const [tenantDataOptions, setTenantDataOptions] = useState<Options[]>();
  const [deviceDataOptions, setDeviceDataOptions] = useState<Options[]>();
  const [categoryTreeData, setCategoryTreeData] = useState<CategoryTree[]>();
  const [attributeDataOptions, setAttributeDataOptions] = useState<Options[]>();

  const [form] = Form.useForm();

  const currentSelectedTenantId = Form.useWatch('tenantMcid', form);

  const currentSelectedCategoryId = Form.useWatch('deviceTypeId', form)?.at(-1);

  const currentSelectedDeviceId = Form.useWatch('deviceIdList', form);

  const [editDevices, setEditDevices] = useState<{ value: number; label: string }[]>([]);

  useAsyncEffect(async () => {
    const res = await Promise.all([apiCustomerGetRootGet({ check: 'true' }), getDeviceTypeData()]);
    const [tenantData, categoryData] = res;

    setTenantDataOptions(tenantData.map(item => ({ label: item.name, value: item.mcid })));

    setCategoryTreeData(transformDataForCascader(categoryData?.tree ?? []));
  }, []);

  useUpdateEffect(() => {
    if (currentSelectedCategoryId && currentSelectedTenantId) {
      getDeviceList({
        tenantMcid: currentSelectedTenantId,
        deviceTypeId: currentSelectedCategoryId,
      }).then(res => {
        setDeviceDataOptions(res.map(item => ({ label: item.name, value: item.id })));
        // 默认全部选中
        form.setFieldsValue({ deviceIdList: res.map(item => ({ label: item.name, value: item.id })) });
      });
    } else {
      form.setFieldsValue({ dataPropertyIdList: undefined, deviceIdList: undefined });
      setAttributeDataOptions([]);
      setDeviceDataOptions([]);
    }
  }, [currentSelectedTenantId, currentSelectedCategoryId]);

  useUpdateEffect(() => {
    form.setFieldsValue({ dataPropertyIdList: undefined });
    setAttributeDataOptions([]);

    if (!currentSelectedDeviceId?.length) return;
    console.log(currentSelectedDeviceId, 'currentSelectedDeviceId');
    apiDataPropertyListByDeviceIdGet({
      deviceIds: currentSelectedDeviceId.map((v: any) => v.value),
    }).then(res => {
      setAttributeDataOptions(res.map(item => ({ label: item.name, value: item.id })));
    });
  }, [currentSelectedDeviceId]);

  const fetchDevicesRef = useRef(0);
  const [fetchingDevices, setFetchingDevices] = useState(false);

  const combineDeviceOptions = useCallback(
    (res: Device[]) => {
      // 筛选不在已选的设备里的设备
      const notIncludedDevices = res
        .filter(v => !editDevices.map(v => v.value).includes(v.id))
        .sort((a, b) => Date.parse(b.updateTime) - Date.parse(a.updateTime));
      // 最多展示 20 个数据, 需要保证之前已选的设备在下拉框里, 同时请求得到的新设备在按 updateTime 排序插入
      setDeviceDataOptions([
        ...editDevices,
        ...notIncludedDevices.slice(0, 20 - editDevices.length).map(v => ({ value: v.id, label: v.name })),
      ]);
    },
    [editDevices]
  );

  const onDeviceSearch = useMemo(() => {
    const loadDeviceOptions = (searchValue: string) => {
      if (currentSelectedTenantId && currentSelectedCategoryId) {
        fetchDevicesRef.current += 1;
        const fetchId = fetchDevicesRef.current;
        setFetchingDevices(true);

        getDeviceList({
          tenantMcid: currentSelectedTenantId,
          name: searchValue,
          deviceTypeId: currentSelectedCategoryId,
        })
          .then(res => {
            if (fetchId !== fetchDevicesRef.current) {
              // for fetch callback order
              return;
            }
            combineDeviceOptions(res);
          })
          .finally(() => {
            setFetchingDevices(false);
          });
      }
    };

    return debounce(loadDeviceOptions, 800);
  }, [combineDeviceOptions, currentSelectedCategoryId, currentSelectedTenantId]);

  const onDeleteModalCancel = () => {
    Modal.confirm({
      content: '您是否取消当前操作？',
      onOk() {
        setOpen(false);
      },
    });
  };

  const onDeleteModalOk = () => {
    form.validateFields().then(values => {
      const params = {
        ...values,
        deviceIdList: values.deviceIdList.map((i: any) => i.value),
        deviceTypeId: Number(values.deviceTypeId?.at(-1)),
        startTime: dayjs(values.date[0]).unix(),
        endTime: dayjs(values.date[1]).unix(),
      };
      delete params.date;

      Modal.confirm({
        title: '删除提示',
        content: (
          <>
            <div>您确定删除选定设备下对应时段内的数据么，数据删除后将不能恢复，并会记录操作日志。</div>
            <Form form={form} style={{ marginTop: 30 }}>
              <Form.Item
                label="操作密码"
                name="operationPassword"
                rules={[{ required: true, message: '请输入操作密码' }]}
              >
                <Input.Password autoComplete="new-password" placeholder="请输入操作密码" />
              </Form.Item>
            </Form>
          </>
        ),
        onOk: () => {
          return form.validateFields().then(() => {
            return apiV2ValueDelDelValuePost({
              ...(params as RequestWithoutValidCode),
              operationPassword: form.getFieldValue('operationPassword'),
            })
              .onError(error => {
                const { errorMessage } = error?.cause;
                message.error(errorMessage);
                throw error;
              })
              .then(() => {
                message.info('删除成功');
                setOpen(false);
                onReset();
              });
          });
        },
      });
    });
  };

  return (
    <Modal
      title="删除数采模拟数据"
      width={600}
      open={true}
      onCancel={onDeleteModalCancel}
      onOk={onDeleteModalOk}
      destroyOnClose
      okText="删除"
      cancelText="取消"
    >
      <div className={styles.wrapper}>
        <Form {...formLayout} form={form}>
          <Form.Item label="选择租户" name="tenantMcid" rules={[{ required: true, message: '请选择租户' }]}>
            <Select options={tenantDataOptions} allowClear />
          </Form.Item>
          <Form.Item label="选择类目" name="deviceTypeId" rules={[{ required: true, message: '请选择类目' }]}>
            <Cascader options={categoryTreeData} allowClear />
          </Form.Item>
          <Form.Item
            label="选择设备"
            name="deviceIdList"
            rules={[
              {
                validator(_, value) {
                  const tenantMcid = form.getFieldValue('tenantMcid');
                  const deviceTypeId = form.getFieldValue('deviceTypeId');
                  if (!value && (!tenantMcid || !deviceTypeId)) {
                    return Promise.reject('请先选择租户和类目');
                  }
                  if (!value) {
                    return Promise.reject('请选择设备');
                  }
                  return Promise.resolve();
                },
              },
            ]}
            validateTrigger="onBlur"
          >
            <Select
              labelInValue
              allowClear
              filterOption={false}
              mode="multiple"
              value={editDevices}
              options={deviceDataOptions}
              onChange={(values: { label: string; value: number }[]) => {
                setEditDevices(values);
              }}
              onSearch={onDeviceSearch}
              onBlur={() => {
                // 失焦后清空搜索内容并且请求一次所有的设备
                if (currentSelectedCategoryId && currentSelectedTenantId) {
                  getDeviceList({
                    tenantMcid: currentSelectedTenantId,
                    deviceTypeId: currentSelectedCategoryId,
                  }).then(res => {
                    combineDeviceOptions(res);
                  });
                }
              }}
              notFoundContent={fetchingDevices ? <Spin size="small" /> : null}
            />
          </Form.Item>
          <Form.Item
            label="选择属性"
            name="dataPropertyIdList"
            rules={[
              {
                validator(_, value) {
                  const deviceIdList = form.getFieldValue('deviceIdList');
                  if (!deviceIdList) {
                    return Promise.reject('请先选择设备');
                  }
                  if (!value) {
                    return Promise.reject('请选择属性');
                  }
                  return Promise.resolve();
                },
              },
            ]}
            validateTrigger="onBlur"
          >
            <Select options={attributeDataOptions} mode="multiple" allowClear />
          </Form.Item>
          <Form.Item
            label="数据删除区间"
            name="date"
            rules={[
              { required: true, message: '请选择数据删除区间' },
              () => ({
                validator(_, value) {
                  if (value && value[0] && value[1]) {
                    const [startDate, endDate] = value;

                    if (endDate.isBefore(startDate)) {
                      return Promise.reject('结束时间必须大于开始时间');
                    }

                    if (endDate.diff(startDate, 'days') > 30) {
                      return Promise.reject('一次最多删除一个月数据');
                    }
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker.RangePicker showTime className={styles.rangePicker} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default DeleteModal;
