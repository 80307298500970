import { EllipsisSpan, Form, Input, Modal, Paging, Upload, usePaging, useUpdate } from '@maxtropy/components';
import { Button, Space, Table } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { copyNavigation, CusNavListResponse, getNavigationList, updateNavgationName } from '../../../api/cc-navigation';
import { PermissionsType } from '../../../common/permissionsConst';
import { useHasPermission } from '../../../utils/hooks';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import { apiV2NavigationImportPost } from '@maxtropy/tody-mgmt-apis-v2';
import qs from 'qs';
import { extname } from '@/shared/utils/utils';

const columns = [
  {
    title: '导航方案编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '导航方案名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const fileSize = 50 * 1024 * 1024;

const CusNavList: FC = () => {
  const hasNavAddAndEdit = useHasPermission(PermissionsType.B_NAVIGATIONPROGRAMUPDATECOPY); // 编辑复制

  const pagingInfo = usePaging(50);
  const [loading, setLoading] = useState<boolean>(false); // 加载状态
  const [form] = Form.useForm(); // 导航方案名称修改form
  const [importForm] = Form.useForm(); // 导入导航form
  const [opened, setOpened] = useState<boolean>(false);
  const [currentRecord, setCurrentRecord] = useState<Partial<CusNavListResponse>>();
  const [data, setData] = useState<CusNavListResponse[]>([]); // 后端返回数据
  const navigate = useNavigate();
  const [updateState, updateFn] = useUpdate(); // 更新
  const [uploadState, setUploadState] = useState<boolean>(false);

  const [importNavOpen, setImportNavOpen] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>(); // 文件名称

  useEffect(() => {
    setLoading(true);
    getNavigationList().then(res => {
      setData(res);
      setLoading(false);
    });
  }, [updateState]);

  useEffect(() => {
    if (currentRecord) {
      form.setFieldsValue({
        name: currentRecord.name,
      });
    }
  }, [currentRecord, form]);

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      // width: 183,
      // fixed: 'right' as 'right',
      render: (record: CusNavListResponse) => (
        <Space size={16}>
          {hasNavAddAndEdit && record.id !== 1 && (
            <Button
              style={{ paddingLeft: 0, paddingRight: 0 }}
              type="link"
              onClick={() => {
                setCurrentRecord(record);
              }}
            >
              编辑导航方案名称
            </Button>
          )}
          {hasNavAddAndEdit && record.id !== 1 && (
            <Button
              style={{ paddingLeft: 0, paddingRight: 0 }}
              type="link"
              onClick={() => {
                navigate(`/platform/navigationSettings/scheme/edit/${record.id}?name=${record.name}`);
              }}
            >
              编辑导航方案内容
            </Button>
          )}
          {record.id === 1 && (
            <Button
              style={{ paddingLeft: 0, paddingRight: 0 }}
              type="link"
              onClick={() => {
                navigate(`/platform/navigationSettings/scheme/edit/${record.id}`);
              }}
            >
              查看
            </Button>
          )}
          {hasNavAddAndEdit && (
            <Button
              style={{ paddingLeft: 0, paddingRight: 0 }}
              type="link"
              onClick={() => {
                copyNavigation(String(record.id)).then(() => {
                  updateFn();
                });
              }}
            >
              复制
            </Button>
          )}
          <Button
            style={{ paddingLeft: 0, paddingRight: 0 }}
            type="link"
            onClick={() => {
              window.open(`/platform/navigationSettings/scheme/detail/${record.id}`);
            }}
          >
            操作日志
          </Button>
          <Button
            style={{ paddingLeft: 0, paddingRight: 0 }}
            type="link"
            onClick={() => {
              window.open(
                `/api/v2/navigation/export` +
                  qs.stringify(
                    {
                      id: record.id,
                    },
                    { addQueryPrefix: true }
                  )
              );
            }}
          >
            导航导出
          </Button>
        </Space>
      ),
    },
  ];

  const onClose = () => {
    setOpened(false);
    setCurrentRecord(undefined);
    form.resetFields();
  };

  const onOk = () => {
    form.validateFields().then((data: { name: string }) => {
      if (currentRecord) {
        updateNavgationName({
          id: currentRecord.id!,
          name: data.name,
        }).then(() => {
          onClose();
          updateFn();
        });
      }
    });
  };

  const onFinish = (values: any) => {
    setUploadState(true);
    apiV2NavigationImportPost({ fileKey: values?.fileKey, fileName: fileName })
      .then(_ => {
        Modal.success({
          title: '导入成功',
          okText: '确定',
        });
        updateFn?.();
        setImportNavOpen(false);
      })
      .finally(() => setUploadState(false));
  };

  return (
    <>
      <div style={{ padding: '20px', margin: '0 10px' }}>
        <Space size={10} direction="vertical" className={styles.btnBlank}>
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setImportNavOpen(true)}>
            导航导入
          </Button>
        </Space>
        <Table
          rowKey="id"
          sticky
          scroll={{ x: 1300 }}
          loading={loading}
          columns={buildColumns}
          dataSource={data}
          pagination={false}
        />
        <Paging pagingInfo={pagingInfo} />
      </div>
      <Modal
        title={`导航方案名称修改`}
        width={600}
        open={!!currentRecord || opened}
        onCancel={onClose}
        destroyOnClose
        onOk={onOk}
        bodyStyle={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Form style={{ width: 450 }} form={form} labelCol={{ flex: '110px' }} labelAlign="right">
          <Form.Item
            name="name"
            label="导航方案名称"
            rules={[
              { required: true, message: '请输入方案名称', whitespace: true, type: 'string' },
              { max: 20, message: '最多输入20个字' },
            ]}
          >
            <Input maxLength={20} placeholder="请输入" />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={'导入导航'}
        open={importNavOpen}
        onCancel={() => setImportNavOpen(false)}
        onOk={() => {
          importForm.submit();
        }}
        confirmLoading={uploadState}
      >
        <>
          <InfoCircleOutlined
            style={{ fontSize: '14px', margin: '0px 6px 24px 0px', color: 'var(--mx-warning-color)' }}
          />
          <span style={{ fontSize: '14px', color: 'rgba(var(--base-text-color),0.35)' }}>
            请注意检查导航文件中对应导航的域名是否正确
          </span>
        </>
        <Form form={importForm} onFinish={onFinish}>
          <Form.Item
            label="选择文件"
            className={styles.uploadSty}
            rules={[{ required: true, message: '请先上传文件' }]}
            name="fileKey"
          >
            <Upload
              action="/api/file-center/upload"
              listType="text"
              accept={['.xlsx', '.xls']}
              uploadText="选择导入文件"
              beforeUpload={file => {
                const { name } = file;
                const extName = extname(name);
                const limitFileSize = file.size <= fileSize;
                const limitFileType = extName === '.xlsx' || extName === '.xls';
                if (!limitFileSize) {
                  Modal.warning({
                    content: `上传文件的大小不得超过50M`,
                  });
                  return false;
                }
                if (!limitFileType) {
                  Modal.warning({
                    content: `文件格式错误！仅支持.xls、.xlsx`,
                  });
                  return false;
                }
                const newStr = name.replace(extName, ''); // 去除后缀
                setFileName(newStr);
                return true;
              }}
              fileSize={50}
              tip="仅支持：.xls .xlsx，大小不超过50MB"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CusNavList;
