import React, { CSSProperties, useCallback } from 'react';
import { Form, Row, Col } from 'antd';
import { isNil } from 'lodash-es';
import { EdgeDeviceTemplateDetail, IotProtocolType, DriveTypeLabels, DriveType } from '../../types';
import { templateFormItemsDisplayByType } from './factory';
import { useQuery } from '../../utils/utils';
import { getIotProtocols } from '../../api/options';
import useIsGateway from '../../hooks/useIsGateway';
import ShowInput from '../ShowInput';

export interface EdgeDeviceTemplateFormProps {
  type: IotProtocolType;
  row?: EdgeDeviceTemplateDetail;
  className?: string;
  style?: CSSProperties;
}

const EdgeDeviceTemplateDisplay: React.FC<EdgeDeviceTemplateFormProps> = ({ type, row, className, style }) => {
  const TemplateFormItemsDisplay = templateFormItemsDisplayByType[type];
  const { data: iotProtocols } = useQuery(useCallback(getIotProtocols, []));
  const { isGateway } = useIsGateway(row?.deviceTypeId);

  return (
    <Form className={className} style={style}>
      <Row>
        <Col span={12}>
          <Form.Item label="模板名称">{row?.name}</Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="所属类目">{row?.deviceTypeName}</Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="物模型型号">
            <ShowInput
              value={row?.physicalModelId ? `${row?.modelNo}-${row?.manufacturerName}` : row?.physicalModelId}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="所属型号">{row?.deviceModelName}</Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="物联层协议">{iotProtocols?.find(item => item.id === row?.iotProtocol)?.name}</Form.Item>
        </Col>
        {row?.iotProtocol === IotProtocolType.MOCKINGBIRD && !isGateway && (
          <Col span={12}>
            <Form.Item label="驱动类型">
              {!isNil(row?.driveType) ? DriveTypeLabels[row?.driveType as DriveType] : '--'}
            </Form.Item>
          </Col>
        )}
        <Col span={12}>
          <Form.Item label="模板备注">{row?.remark || '-'}</Form.Item>
        </Col>
        {TemplateFormItemsDisplay ? <TemplateFormItemsDisplay row={row} /> : null}
      </Row>
    </Form>
  );
};

export default EdgeDeviceTemplateDisplay;
