import { generateLabelList } from '../../utils/utils';
import { CheckType, CheckTypeLabels, CrcOrder, CrcOrderLabels, SerialPort, SerialPortLabels } from '../../types';

export const halfFormItemLayout = {
  labelCol: { style: { width: 120, alignItems: 'center', display: 'flex', justifyContent: 'flex-end', height: 32 } },
  wrapperCol: { span: 18 },
};

export const SerialPortOptions = generateLabelList(SerialPortLabels, [
  SerialPort.COM1,
  SerialPort.COM2,
  SerialPort.COM3,
  SerialPort.COM4,
]);

export const CheckTypeOptions = generateLabelList(CheckTypeLabels, [
  CheckType.NULL,
  CheckType.ODD,
  CheckType.EVEN,
  CheckType.MARK,
  CheckType.SPACE,
]);

export const CrcOrderOptions = generateLabelList(CrcOrderLabels, [CrcOrder.BIG_END, CrcOrder.SMALL_END]);
