import React, { CSSProperties, useCallback, useState, useEffect, useMemo } from 'react';
import { Form, Input, Row, Col, AutoComplete, Select, FormInstance, Cascader } from 'antd';
import { EdgeDeviceTemplateDetail, IotProtocolType, DriveType, DriveTypeLabels } from '../../types';
import { templateFormItemsByType } from './factory';
import { formatDeviceTypeToDataNode, useQuery } from '../../utils/utils';
import {
  getDeviceTypeTree,
  getIotProtocols,
  getModels,
  getPhysicalModelList,
  PhysicalModelRes,
} from '../../api/options';
import useIsGateway from '../../hooks/useIsGateway';

export interface EdgeDeviceTemplateFormProps {
  row?: EdgeDeviceTemplateDetail;
  className?: string;
  style?: CSSProperties;
  form: FormInstance;
  isCopy?: boolean;
  isEdit?: boolean;
}

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18, style: { width: '100%' } },
};

const EdgeDeviceTemplateForm: React.FC<EdgeDeviceTemplateFormProps> = ({
  form,
  className,
  style,
  row,
  isCopy,
  isEdit,
}) => {
  const TemplateFormItems = templateFormItemsByType[IotProtocolType.ESTUN];
  const { data: deviceTypes } = useQuery(useCallback(() => getDeviceTypeTree(), []));
  const [deviceTypeId, setDeviceTypeId] = useState<number>();
  const { isGateway } = useIsGateway(deviceTypeId);
  useEffect(() => {
    if (row) {
      setDeviceTypeId(row.deviceTypeId);
    }
  }, [row]);
  const { data: models } = useQuery(
    useCallback(() => (deviceTypeId ? getModels(deviceTypeId) : Promise.resolve([])), [deviceTypeId])
  );
  const { data: iotProtocols } = useQuery(useCallback(getIotProtocols, []));
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const onSearch = (searchText: string) => {
    setOptions(
      !searchText
        ? []
        : models!
            .filter(item => item.name.toLowerCase().includes(searchText.toLowerCase()))
            .map(item => ({ value: item.name, id: item.id }))
    );
  };

  const [physicalModelList, setPhysicalModelList] = useState<PhysicalModelRes[]>([]); // all 物模型型号列表

  useEffect(() => {
    // 物模型型号
    if (deviceTypeId) {
      getPhysicalModelList({ deviceTypeIds: [deviceTypeId] }).then(setPhysicalModelList);
    }
  }, [deviceTypeId]);

  const objectModalTypeOptions = useMemo(() => {
    return physicalModelList?.map(i => ({ label: `${i.modelNo}-${i.manufacturerName}`, value: i.id })) || [];
  }, [physicalModelList]);

  return (
    <Form form={form} layout="vertical" className={className} style={style} {...formLayout}>
      <Form.Item noStyle name="deviceTypeId" />
      <Row>
        <Col span={8}>
          <Form.Item name="name" label="模板名称" required rules={[{ required: true }, { max: 20 }]}>
            <Input style={{ width: '100%' }} placeholder="请输入" />
          </Form.Item>
        </Col>
        {isCopy ? (
          <Col span={8}>
            <Form.Item name="deviceTypeName" label="所属类目" required>
              <Input disabled />
            </Form.Item>
          </Col>
        ) : (
          <Col span={8}>
            <Form.Item
              name="deviceType"
              label="所属类目"
              required
              rules={isEdit ? undefined : [{ required: true, message: '请选择所属类目' }]}
            >
              {isEdit ? (
                <div>{row?.deviceTypeName}</div>
              ) : (
                <Cascader
                  style={{ width: '100%' }}
                  allowClear={false}
                  // disabled={isCopy}
                  options={formatDeviceTypeToDataNode(deviceTypes?.tree ?? [])}
                  fieldNames={{ label: 'name', value: 'id' }}
                  onChange={(e: any) => {
                    if (e.length < 3) {
                      form.setFieldsValue({ deviceTypeId: undefined });
                      form.setFieldsValue({ deviceType: undefined });
                      setDeviceTypeId(undefined);
                    }
                    form.setFieldsValue({
                      deviceTypeId: e[2] as number | undefined,
                    });
                    setDeviceTypeId(e[2] as number | undefined);
                    form.setFieldsValue({ objectModalType: undefined });
                  }}
                  placeholder="请选择"
                />
              )}
            </Form.Item>
          </Col>
        )}
        <Col span={8}>
          <Form.Item
            name="objectModalType"
            label="物模型型号"
            rules={
              isCopy
                ? [{ message: '请注意，若更换物模型型号，则对应物模型型号下的建模数据点会自动移除', warningOnly: true }]
                : undefined
            }
          >
            {isEdit ? (
              <div style={{ marginTop: -10 }}>
                {row?.physicalModelId ? `${row!.modelNo}-${row!.manufacturerName}` : '--'}
              </div>
            ) : (
              <Select
                allowClear
                optionFilterProp="label"
                showSearch
                disabled={!isCopy && !form.getFieldValue('deviceType')}
                placeholder={form.getFieldValue('deviceType') ? '请选择' : '请先选择所属类目'}
                options={objectModalTypeOptions}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="deviceModelName" label="所属型号" rules={[{ required: true }]}>
            {isEdit ? (
              <div style={{ marginTop: -10 }}>{row?.deviceModelName}</div>
            ) : (
              <AutoComplete
                style={{ width: '100%' }}
                disabled={!deviceTypeId}
                onChange={value => {
                  form.setFieldsValue({
                    deviceModelName: value,
                  });
                }}
                options={options}
                onSearch={onSearch}
                placeholder={deviceTypeId ? '请输入/选择' : '请先选择类目'}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="iotProtocol" label="物联层协议" rules={[{ required: true, message: '请选择物联层协议' }]}>
            {isEdit ? (
              iotProtocols?.find(item => item.id === row?.iotProtocol)?.name
            ) : (
              <Select style={{ width: '100%' }} placeholder="请选择" disabled={isCopy}>
                {iotProtocols?.map(item => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Form.Item noStyle dependencies={['iotProtocol', 'deviceType']}>
          {({ getFieldValue }) =>
            getFieldValue('iotProtocol') === IotProtocolType.MOCKINGBIRD &&
            !isGateway && (
              <Col span={8}>
                <Form.Item name="driveType" label="驱动类型" rules={[{ required: true, message: '请选择驱动类型' }]}>
                  {isEdit ? (
                    <div>{DriveTypeLabels[row?.driveType as DriveType]}</div>
                  ) : (
                    <Select style={{ width: '100%' }} placeholder="请选择" disabled={isCopy}>
                      <Select.Option value={DriveType.MODBUS_RTU}>
                        {DriveTypeLabels[DriveType.MODBUS_RTU]}
                      </Select.Option>
                      <Select.Option value={DriveType.MODBUS_TCP}>
                        {DriveTypeLabels[DriveType.MODBUS_TCP]}
                      </Select.Option>
                      <Select.Option value={DriveType.DLT645_1997}>
                        {DriveTypeLabels[DriveType.DLT645_1997]}
                      </Select.Option>
                      <Select.Option value={DriveType.DLT645_2007}>
                        {DriveTypeLabels[DriveType.DLT645_2007]}
                      </Select.Option>
                      <Select.Option value={DriveType.IEC104}>{DriveTypeLabels[DriveType.IEC104]}</Select.Option>
                      <Select.Option value={DriveType.S7}>{DriveTypeLabels[DriveType.S7]}</Select.Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            )
          }
        </Form.Item>
        <Col span={8}>
          <Form.Item name="remark" label="模板备注" rules={[{ max: 50 }]}>
            <Input style={{ width: '100%' }} placeholder="请输入" />
          </Form.Item>
        </Col>
      </Row>
      {TemplateFormItems ? <TemplateFormItems row={row} form={form} /> : null}
    </Form>
  );
};

export default EdgeDeviceTemplateForm;
