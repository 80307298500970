import qs from 'qs';
import { fetch, Key } from '@maxtropy/components';
import { IotProtocolType, Operator, GatewayNetStatus, GatewayStatus } from '@/shared/types';
import { PageRequest, PageResponse } from './page';
import { Device } from './device';

export enum SystemType {
  ARM,
  X86,
}

export const SYSTEM_TYPE_MAP = {
  [SystemType.ARM]: 'ARM(智能网关)',
  [SystemType.X86]: 'x86(FCS)',
};

export enum DistributeStatus {
  NOTSTART = 1,
  STARTING,
  SUCCESS,
  FAIL,
  // 1：待下发 2：进行中 3：成功 4：失败
}

export interface EdgeNetWorkType {
  distributeStatus: DistributeStatus; // 组网下发状态
  gatewayId: Key;
  gatewayIp: string;
  id: Key;
  netName: string;
  netNo: string;
  netType: NetworkingType;
  netVersion: number;
  tenantMcid: Key;
}
export interface Gateway {
  configUpdated?: boolean;
  createByOrgId: string;
  createByTenantId: string;
  customerFullName: string;
  customerName: string;
  deviceCount: number;
  id: number;
  iotProtocol: IotProtocolType;
  name: string;
  netState: GatewayNetStatus;
  remark?: string;
  serialNumber?: string;
  state: GatewayStatus;
  tenantName: string;
  tenantCode?: string;
  updateSource: Operator;
  updateByUsername: string;
  updateTime: string;
  uploadFrequency?: object;
  systemType?: SystemType;
  edgeNetwork?: EdgeNetWorkType;
}

export type GatewayResponse = PageResponse<Gateway>;

export interface GatewayRequest extends PageRequest {
  name?: string;
  iotProtocol?: IotProtocolType;
  netState?: GatewayNetStatus;
  rootMcid?: string;
  ifCancel?: boolean;
  ifRed?: boolean;
  netName?: string;
  netType?: NetworkingType;
  serialNumber?: string;
  code?: string;
}

export const getGatewayList = (params: GatewayRequest) =>
  fetch<GatewayResponse>(`/api/gateway/page?${qs.stringify(params, { indices: false })}`);

interface DeviceWithEdgeDevice {
  device: Device;
  edgeDevice: {
    id: number;
    edgeGatewayId?: number;
    deviceId?: number;
    deviceTypeId?: number;
    iotProtocol?: number;
    deviceModelId?: IotProtocolType;
    edgeDeviceTemplateId?: number;
    edgeDeviceTemplateVersion?: number;
  };
}

export interface GatewayDetail extends Gateway {
  deviceWithEdgeDeviceDtos: DeviceWithEdgeDevice[];
  deviceId?: number;
  deviceName?: string;
  transferProtocol?: TransferProtocolType;
}

export const fetchGatewayById = (id: string) => fetch<GatewayDetail>(`/api/gateway/${id}`);

export function getGatewayBaseInfoById(gatewayId: Key) {
  return fetch<GatewayDetail>(`/api/gateway/base-info/${gatewayId}`);
}

export enum TransferProtocolType {
  json = 0,
  kryo = 1,
}

export const TransferProtocolTypeDisplay = {
  [TransferProtocolType.json]: 'json',
  [TransferProtocolType.kryo]: 'kryo',
};

export interface CreateGatewayForm {
  name: string;
  createByTenantId: string;
  createByOrgId: string;
  iotProtocol: IotProtocolType;
  protocol: IotProtocolType;
  uploadFrequencyId: number;
  serialNumber: string;
  transferProtocol: TransferProtocolType;
  remark?: string;
  deviceId?: number;
}

export const createGateway = (params: CreateGatewayForm) =>
  fetch<void>(`/api/gateway`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': `${params.createByTenantId}`,
    },
  });

export const updateGateway = (id: string, params: CreateGatewayForm) =>
  fetch<void>(`/api/gateway/${id}`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });

export const changeGatewayStatus = (id: number, state: GatewayStatus) =>
  fetch<void>(`/api/gateway/${id}/state?state=${state}`, {
    method: 'PUT',
  });

export interface DevicePageRequest extends PageRequest {
  name?: string;
  deviceTypes?: Array<number>;
  iotProtocol: IotProtocolType;
  gatewayId: string;
  tag?: string;
}

export function getDevicePage(params: DevicePageRequest): Promise<PageResponse<Device>> {
  return fetch(`/api/gateway/device/page?${qs.stringify(params, { indices: false })}`);
}

export interface GatewayDevicePageRequest extends PageRequest {
  rootMcid: string;
  iotProtocol: IotProtocolType;
  codeOrName?: string;
  primaryTypeId?: number;
  secondaryTypeId?: number;
  edgeGatewayId?: number;
  tag?: string;
}

export function getGatewayDevicePage(params: GatewayDevicePageRequest): Promise<PageResponse<Device>> {
  return fetch(`/api/device/meta/data/device/page?${qs.stringify(params, { indices: false })}`);
}

export function checkSerialNumber(params: { serialNumber: string }): Promise<boolean> {
  return fetch(`/api/gateway/checkSerialNumber?${qs.stringify(params, { indices: false })}`);
}

interface UpdateGatewayDeviceForm {
  edgeGatewayId: number;
  deviceIds: number[];
  protocol: IotProtocolType;
}

export const updateGatewayDevice = (params: UpdateGatewayDeviceForm) =>
  fetch<void>(`/api/gateway/modify`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });

export function getUploadFrequencyOpts(): Promise<object[]> {
  return fetch(`/api/gateway/uploadFrequency`);
}

export interface ApplyLogResponse {
  id: number;
  time: number;
  state: boolean;
  updateByUsername: string;
}

export function getApplyLog(id: number): Promise<ApplyLogResponse[]> {
  return fetch(`/api/gateway/config/distribute/log/${id}`);
}

export const applyGateway = (id: number, tenantMcid: string) => {
  return fetch<{ ret?: string }>(`/api/v2/gateway/config/distribute`, {
    method: 'POST',
    body: JSON.stringify({
      id,
    }),
    headers: {
      'x-tenant-mcid': tenantMcid,
    }
  },  true)
};

export enum NetworkingType {
  STATIC_IP = 1,
}

export const NetworkingTypeDisPlay = {
  [NetworkingType.STATIC_IP]: '静态IP',
};
