import { Button, Form, Input, message, Modal, ShowInput } from '@maxtropy/components';
import React, { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import { apiV2PswResetPhonePost, apiV2PswResetSendCodePost } from '@maxtropy/tody-mgmt-apis-v2';

export interface formSubmitProps {
  validCode: string;
  password: string;
}

export interface IResetPasswordModal {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: formSubmitProps) => Promise<void>;
}

const ResetPasswordModal: FC<IResetPasswordModal> = props => {
  const { visible, onCancel, onSubmit } = props;

  const [form] = Form.useForm();
  const [countdown, setCountdown] = useState<number>(0);

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  // 获取手机号
  useRequest(
    () => {
      return apiV2PswResetPhonePost({});
    },
    {
      onSuccess: data => {
        form.setFieldValue('phoneNumber', maskPhoneNumber(data.phone));
      },
      ready: visible,
      refreshDeps: [visible],
    }
  );

  const handleClick = () => {
    if (countdown === 0) {
      setCountdown(60);
      // 这里可以触发验证码请求
      apiV2PswResetSendCodePost().then(_ => {
        message.success('验证码发送中...');
      });
    }
  };

  const checkUpdateConfirm = (_: any, value: string) => {
    const promise = Promise;
    if (value && value !== form.getFieldValue('password')) {
      return promise.reject('两次输入的密码不匹配');
    }
    return promise.resolve();
  };

  // 手机号隐藏
  const maskPhoneNumber = (phone?: string) => {
    if (!phone || phone.length !== 11) return '手机号格式不正确';
    return phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2');
  };

  const onOK = async () => {
    const values = await form.validateFields();
    onSubmit(values);
  };

  return (
    <Modal
      contentClassName="modal-form-content"
      destroyOnClose
      open={visible}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={onOK}
      title={'重置密码'}
    >
      <Form form={form} labelAlign="left">
        <Form.Item name="phoneNumber" label="手机号">
          <ShowInput />
        </Form.Item>
        <Form.Item name="validCode" label="验证码" rules={[{ required: true, message: '请选择三方设备编码' }]}>
          <div className={styles.verification_box}>
            <Input placeholder="请输入" />
            <Button onClick={handleClick} disabled={countdown > 0}>
              {countdown > 0 ? `重新获取(${countdown}s)` : '获取验证码'}
            </Button>
          </div>
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: '请输入新操作密码' },
            {
              pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\x20-\x7E]{8,}$/,
              message: '密码不少于8位，必须含大小写字母和数字',
            },
          ]}
          label="新操作密码"
        >
          <Input.Password placeholder="请输入至少8位数密码，密码必须含大小写字母和数字" autoComplete="new-password" />
        </Form.Item>
        <Form.Item
          name="validNewPassword"
          rules={[{ required: true, message: '请确认新操作密码' }, { validator: checkUpdateConfirm }]}
          label="确认新操作密码"
        >
          <Input.Password placeholder="请输入至少8位数密码，密码必须含大小写字母和数字" autoComplete="new-password" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ResetPasswordModal;
