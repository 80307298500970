import React, { useRef, useState } from 'react';
import {
  Checkbox,
  EllipsisSpan,
  Filter,
  Paging,
  Select,
  Table,
  Tag,
  useAsync,
  usePaging,
  Wrapper,
  useUpdate,
} from '@maxtropy/components';
import { Button, Cascader, Col, DatePicker, Form, Input, Space, message } from 'antd';
import dayjs from 'dayjs';
import { useRequest, useUpdateEffect } from 'ahooks';

import DeleteModal from '../DeleteModal';
import {
  apiV2ValueDelPagePost,
  apiV2ValueDelRecoveryPost,
  V2ValueDelPagePostResponse,
  V2ValueDelPagePostRequest,
} from '@maxtropy/tody-mgmt-apis-v2';
import { Status, StatusDisplay, StatusColorMap, Type } from './listTypes';
import DeleteDetailModal from '../DeleteDetailModal';
import styles from './index.module.scss';
import { formatOptionData, getChildNodesByParentIds } from '@/shared/components/CascadingMultipleSelector/utils';
import { getDeviceTypeData } from '@/api/attribute';
import { DefaultOptionType } from 'antd/es/cascader';

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

type DataType = Exclude<V2ValueDelPagePostResponse['list'], undefined>[number];

const otherColumns = [
  {
    title: '租户名称',
    width: 100,
    dataIndex: 'tenantName',
    fixed: 'left' as const,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备编号',
    width: 200,
    dataIndex: 'deviceCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    width: 200,
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    width: 100,
    dataIndex: 'deviceTypeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '属性',
    width: 100,
    dataIndex: 'propertyName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '数据删除区间',
    ellipsis: { showTitle: true },
    width: 280,
    render: (v: DataType) => {
      return (
        <EllipsisSpan
          value={`${dayjs(v.deleteStartTime).format(DATE_TIME_FORMAT)}-${dayjs(v.deleteEndTime).format(
            DATE_TIME_FORMAT
          )}`}
        />
      );
    },
  },
  {
    title: '删除操作人',
    width: 100,
    dataIndex: 'userName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 100,
    ellipsis: { showTitle: true },
    render: (v: Status) => (
      <EllipsisSpan
        value={
          v ? (
            <Tag border="solid" color={StatusColorMap[v]}>
              {StatusDisplay[v as Status]}
            </Tag>
          ) : undefined
        }
      />
    ),
  },
  {
    title: '删除操作时间',
    width: 200,
    dataIndex: 'createTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v && dayjs(v).format(DATE_TIME_FORMAT)} />,
  },
  {
    title: '备注',
    width: 100,
    dataIndex: 'type',
    ellipsis: { showTitle: true },
    render: (v: Type) => (
      <EllipsisSpan value={v === Type.EXCEPTION_FILTERING_DELETED ? '异常数据过滤删除' : '手动删除'} />
    ),
  },
];

const routes = [{ name: '数据运营工具' }, { name: '数据模拟' }, { name: '模拟数据删除' }];

interface MockDataDeletionProps {}
const MockDataDeletion: React.FC<MockDataDeletionProps> = () => {
  const [form] = Form.useForm();
  const [deleteModalForm] = Form.useForm();

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<V2ValueDelPagePostRequest>();
  const [list, setList] = useState<DataType[]>();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deleteDetailModalOpen, setDeleteDetailModalOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isBatch, setIsBatch] = useState<boolean>(false);

  const deleteDetailId = useRef<number>(0);
  const deviceTypeData = useAsync(getDeviceTypeData);
  const [updateState, updateFn] = useUpdate();
  const onFinish = (values: any) => {
    const filteredData: V2ValueDelPagePostRequest = {
      ...values,
      deviceTypeIds:
        values.deviceTypeIds?.length > 0
          ? getChildNodesByParentIds(values.deviceTypeIds, deviceTypeData?.tree)
          : undefined,
      startTime: values.time?.[0] ? dayjs(values.time[0]).unix() : undefined,
      endTime: values.time?.[1] ? dayjs(values.time[1]).unix() : undefined,
    };
    if (filteredData.type) {
      // 1、人工删除 2、异常检测自动删除
      filteredData.type = 2;
    } else {
      delete filteredData.type;
    }

    setSearchParams(filteredData);
    setPageOffset(1);
  };

  const onReset = () => {
    form.resetFields();
    setSearchParams({});
    setPageOffset(1);
  };

  const { loading } = useRequest(
    () =>
      apiV2ValueDelPagePost({
        page: pageOffset,
        size: pageSize,
        ...searchParams,
      }),
    {
      onSuccess: data => {
        setTotalCount(data.total as number);
        setList(data.list);
      },
      refreshDeps: [pageOffset, pageSize, searchParams, setTotalCount, updateState],
    }
  );

  useUpdateEffect(() => {
    deleteModalOpen && deleteModalForm.resetFields();
  }, [deleteModalOpen]);

  const batchRecovery = () => {
    apiV2ValueDelRecoveryPost({ ids: selectedRowKeys as number[] }).then(res => {
      message.info('操作成功');
      setSelectedRowKeys([]);
      updateFn();
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.status === Status.RECOVERED || record.type === Type.MANUALLY_DELETED,
    }),
    preserveSelectedRowKeys: true,
  };

  const columns = [
    ...otherColumns,
    {
      title: '操作',
      fixed: 'right' as const,
      width: 100,
      render: (_record: DataType) => (
        <Button
          type="link"
          onClick={() => {
            deleteDetailId.current = _record.id!;
            setDeleteDetailModalOpen(true);
          }}
        >
          详情
        </Button>
      ),
    },
  ];

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const filters = (
    <>
      <Filter form={form} onFinish={onFinish} onReset={onReset}>
        <Col span={6}>
          <Form.Item label="租户" name="tenantName">
            <Input placeholder="请输入租户名称搜索" allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="编号/名称" name="deviceCodeOrName">
            <Input placeholder="请输入编号/名称搜索" allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="删除操作人" name="userName">
            <Input placeholder="请输入操作人搜索" allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="删除操作时间" name="time">
            <DatePicker.RangePicker showTime className={styles.rangePicker} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="deviceTypeIds" label="所属类目">
            <Cascader
              options={formatOptionData(deviceTypeData)}
              allowClear={false}
              fieldNames={{ children: 'child' }}
              multiple
              maxTagCount="responsive"
              showSearch={{ filter }}
              placeholder={'请选择所属类目'}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="type" valuePropName="checked">
            <Checkbox>异常数据过滤删除</Checkbox>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="status" label="状态">
            <Select placeholder="请选择" allowClear>
              {Object.entries(StatusDisplay).map(([value, label]) => {
                return (
                  <Select.Option key={value} value={Number(value)}>
                    {label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Filter>
    </>
  );

  return (
    <>
      <Wrapper routes={routes} filters={filters} className={styles.wrapper}>
        <Space size={8}>
          {isBatch ? (
            <>
              <Button
                type="primary"
                className={styles.deleteBtn}
                disabled={selectedRowKeys && selectedRowKeys.length < 1}
                onClick={batchRecovery}
              >
                恢复
              </Button>
              <Button
                className={styles.deleteBtn}
                onClick={() => {
                  setSelectedRowKeys([]);
                  setIsBatch(false);
                }}
              >
                取消
              </Button>
            </>
          ) : (
            <>
              <Button type="primary" className={styles.deleteBtn} onClick={() => setDeleteModalOpen(true)}>
                删除模拟数据
              </Button>
              <Button type="primary" className={styles.deleteBtn} onClick={() => setIsBatch(true)}>
                批量恢复数据
              </Button>
            </>
          )}
        </Space>
        <Table
          rowKey="id"
          columns={columns}
          loading={loading}
          dataSource={list}
          pagination={false}
          scroll={{ x: 1900 }}
          rowSelection={isBatch ? rowSelection : undefined}
        />
        <Paging pagingInfo={pagingInfo} />
        {deleteModalOpen && <DeleteModal setOpen={setDeleteModalOpen} onReset={onReset} />}

        <DeleteDetailModal
          open={deleteDetailModalOpen}
          setOpen={setDeleteDetailModalOpen}
          id={deleteDetailId.current}
          updateFn={updateFn}
        />
      </Wrapper>
    </>
  );
};

export default MockDataDeletion;
