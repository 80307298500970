import { FC, useState, useEffect } from 'react';
import { Button, Space, Form, Col, Input, Select } from 'antd';
import { Wrapper, Filter, Table, Paging, usePaging, EllipsisSpan } from '@maxtropy/components';
import { TenantStatus, TenantStatusDisplay } from '../../api/cc-const';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';

import { QlTenantPageResponse, getQlTenantList, QlTenantRequest } from '../../api/cc-tenant-ql';
import { useHasPermission } from '../../utils/hooks';
import { PermissionsType } from '../../common/permissionsConst';

const routes = [{ name: '租户管理' }, { name: '租户组织权限和账户' }, { name: '轻流开户' }];

export const BadgeTenantStatusDisplayColor = {
  [TenantStatus.OPENACCOUNT]: '#52C41A',
  [TenantStatus.NOACCOUNT]: '#F5222D',
};

export const getBadgeTenantStatusDisplayColor = (value: boolean) => {
  return value
    ? BadgeTenantStatusDisplayColor[TenantStatus.OPENACCOUNT]
    : BadgeTenantStatusDisplayColor[TenantStatus.NOACCOUNT];
};

export const getTenantStatusDisplay = (value: boolean) => {
  return value ? TenantStatusDisplay[TenantStatus.OPENACCOUNT] : TenantStatusDisplay[TenantStatus.NOACCOUNT];
};

const columns = [
  {
    title: '租户CODE',
    dataIndex: 'tenantCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '租户名称',
    dataIndex: 'tenantName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '轻流开户状态',
    dataIndex: 'qflowAccountOpenEnable',
    ellipsis: { showTitle: true },
    render: (v: boolean) => {
      return <EllipsisSpan value={v ? '已开户' : '未开户'} />;
    },
  },
  {
    title: '轻流部门ID',
    dataIndex: 'qflowDeptId',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '轻流工作区ID',
    dataIndex: 'qingFlowWorkspaceIdCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '轻流工作区名称',
    dataIndex: 'qingFlowWorkspaceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '配额使用',
    dataIndex: 'userQuota',
    ellipsis: { showTitle: true },
    render: (v: number, record: QlTenantPageResponse) => {
      let { usedUserQuota } = record;
      let used = usedUserQuota ?? 0;
      let total = v ?? 0;
      let colors = used > total ? styles.errTip : used < total ? styles.successTip : styles.warningTip;
      return <EllipsisSpan value={<span className={colors}>{used + '/' + total}</span>} />;
    },
  },
];

export type FilterParams = Omit<QlTenantRequest, 'count' | 'offset'>;

const List: FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<QlTenantPageResponse[]>([]);
  const [searchParams, setSearchParams] = useState<FilterParams>({
    qFlowAccountOpenEnable: true,
  });

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const showQlLinkBtn = useHasPermission(PermissionsType.B_QING_FLOW_ROLE_LINK);
  const showQlAccountOpenBtn = useHasPermission(PermissionsType.B_QING_FLOW_ACCOUNT_OPEN);
  const showQlAccountOpenConfigBtn = useHasPermission(PermissionsType.B_QING_FLOW_ACCOUNT_OPEN_CONFIG);

  // 查询列表
  useEffect(() => {
    setLoading(true);
    getQlTenantList({ ...searchParams, count: pageSize, offset: pageOffset })
      .then(res => {
        setTotalCount(res.total);
        setData(res.contents);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, pageSize, pageOffset, setTotalCount]);

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as const,
      width: 300,
      dataIndex: 'operation',
      render: (value: any, record: QlTenantPageResponse) => {
        return (
          <Space size={16}>
            {showQlAccountOpenBtn && (
              <Button disabled={record.qflowAccountOpenEnable} type="link">
                <Link
                  to={`/tenant/customer/oalite/workspace/create/${record.tenantMcid}?tenantCode=${record.tenantCode}&tenantName=${record.tenantName}`}
                >
                  开户
                </Link>
              </Button>
            )}
            {showQlAccountOpenConfigBtn && (
              <Button disabled={!record.qflowAccountOpenEnable} type="link">
                <Link to={`/tenant/customer/oalite/create/${record.tenantMcid}/config`}>开户设置</Link>
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const onFinish = (value: FilterParams) => {
    setSearchParams(value);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({
      qFlowAccountOpenEnable: true,
    });
    setPageOffset(1);
  };
  // 打开轻流页面
  const linkToQl = () => {
    // prod环境配置dynamic/config文件
    let qlPath = process.env.REACT_APP_QINGFLOWPATH ?? window.QINGFLOWPATH;
    window.open(qlPath, '_blank');
  };
  const filters = (
    <Filter<FilterParams>
      form={form}
      onFinish={onFinish}
      onReset={onReset}
      initialValues={{
        qFlowAccountOpenEnable: true,
      }}
    >
      <>
        <Col span={6}>
          <Form.Item name="tenantCode" label="租户CODE">
            <Input placeholder="输入完整的租户CODE" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="tenantName" label="租户名称">
            <Input placeholder="请输入全部/部分租户名称" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="qFlowAccountOpenEnable" label="轻流开户状态">
            <Select placeholder="请选择租户状态" allowClear>
              <Select.Option value={true}>已开户</Select.Option>
              <Select.Option value={false}>未开户</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  return (
    <Wrapper routes={routes} filters={filters}>
      <div className={styles.operationArea}>
        <Space>
          {showQlLinkBtn && (
            <Button type="primary" onClick={linkToQl}>
              轻流角色绑定
            </Button>
          )}
        </Space>
      </div>
      <Table rowKey="tenantId" sticky scroll={{ x: 1200 }} loading={loading} dataSource={data} columns={buildColumns} />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default List;
