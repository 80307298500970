import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Col, Form, Input, Modal, Radio, Row, Select, message } from 'antd';
import { isEmpty, isNil } from 'lodash-es';
import {
  ActionType,
  BIT_MODE,
  BIT_NUM,
  BYTE_NUM,
  DataPointType,
  DataProperty,
  DataPropertyType,
  VALUE_TYPE,
  ModbusPointBase,
  BYTE_LENGTH,
} from '../../../../../types';
import { PointContext } from '../../../contextTypes';
import { useDataProperties } from '../hooks';
import {
  bitModeFormatter,
  byteNumFormatter,
  bitNumFormatter,
  checkNumCoefficient,
  checkNum,
  identifierValidatorBuilder,
  byteLengthFormatter,
  handleS7ModeFormatter2,
  handleS7ModeFormatter4,
  s7ValueTypeFormatter2,
  s7ValueTypeFormatter1,
  s7ValueTypeFormatter4,
} from '../entity';
import FormSubmit from '../../FormSubmit';
import EnumMapping, { EditableTableRef } from '../components/EnumMapping';
import { FilterMode, FilterModeDisplay } from '@/shared/const';
import { validatorValueRange } from '@/shared/utils/utils';
import { InputNumber } from '@maxtropy/components';

const { Option } = Select;

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

export function getValueTypeMapping(length: BYTE_LENGTH | undefined) {
  let newValueType;
  if (!length) {
    return s7ValueTypeFormatter1;
  } else if (length === BYTE_LENGTH.ONE) {
    newValueType = s7ValueTypeFormatter2;
  } else if (length === BYTE_LENGTH.TWO) {
    newValueType = s7ValueTypeFormatter2;
  } else if (length === BYTE_LENGTH.FOUR) {
    newValueType = s7ValueTypeFormatter4;
  }

  return newValueType;
}

export function getHandleModeMapping(length: BYTE_LENGTH | undefined) {
  if (!length) {
    return;
  }
  let newHandleMode;
  if (length === BYTE_LENGTH.TWO) {
    newHandleMode = handleS7ModeFormatter2;
  } else if (length === BYTE_LENGTH.FOUR) {
    newHandleMode = handleS7ModeFormatter4;
  }
  return newHandleMode;
}

export function getMode(bitMode: BIT_MODE | undefined, bitNum: BIT_NUM | undefined, byteNum: BYTE_NUM | undefined) {
  let value;
  if (bitMode === BIT_MODE.NULL) {
    value = bitModeFormatter[BIT_MODE.NULL];
  } else if (bitMode === BIT_MODE.BIT && !isNil(bitNum)) {
    value = bitNumFormatter[bitNum];
  } else if (bitMode === BIT_MODE.BYTE && !isNil(byteNum)) {
    value = byteNumFormatter[byteNum];
  }
  return value;
}

export const computeNextStarting = (currentStarting: string, length?: number): string => {
  const next = parseInt(currentStarting, 16) + (length ?? 0);
  return next.toString(16);
};

export function getDefaultHandleModeAndValueType(length: BYTE_LENGTH | undefined) {
  if (length === BYTE_LENGTH.ZERO) {
    return {
      valueType: VALUE_TYPE.BOOLEAN, // 布尔
    };
  }
  if (length === BYTE_LENGTH.ONE) {
    return {
      valueType: VALUE_TYPE.UNSIGNEDINTERGER, // 无符号整型数
    };
  }
  if (length === BYTE_LENGTH.TWO) {
    return {
      handleMode: 1, // 2,1
      valueType: VALUE_TYPE.UNSIGNEDINTERGER, // 无符号整型数
    };
  }
  if (length === BYTE_LENGTH.FOUR) {
    return {
      handleMode: 3, // 4,3,2,1
      valueType: VALUE_TYPE.FLOAT, // 双精度浮点数
    };
  }
  return {
    handleMode: undefined,
    valueType: undefined,
  };
}

export interface FormModbusPoint extends ModbusPointBase {
  actionType?: ActionType;
  id?: number;
  pointType?: DataPointType;
  hasProperty?: boolean;
  writable?: boolean;
  dataPropertyId?: number;
  physicalUnitId?: number;
  dataPropertyName?: string;
  physicalUnitGeneralName?: string;
  identifier?: string;
  address?: string;
  length?: any;
}

export function parseDBString(input?: string): { length?: BYTE_LENGTH} {
  if (!input) {
    return {};
  }
  // 定时器/计数器与后端统一，暂不考虑

  // 匹配数据块数据格式
  const dbBlockRegex1 = /^DB(\d+)\.DB(B|D|W)(\d+)$/;
  const dbBlockRegex2 = /^DB(\d+)\.DBX(\d+)(\.(\d+))$/;
  // 匹配存储区格式（不带位）
  const memoryAreaRegexNoBit = /^(I|Q|M)(B|W|D)?(\d+)$/;
  // 匹配存储区格式（带位）
  const memoryAreaRegexWithBit = /^(I|Q|M)(\d+)\.(\d+)$/;

  let result: { length?: BYTE_LENGTH} = {};
  // 尝试匹配数据块数据格式
  let dbMatch = dbBlockRegex1.exec(input) || dbBlockRegex2.exec(input);
  if (dbMatch) {
    const [, , type] = dbMatch;
    if (type && ['B', 'W', 'D'].includes(type)) {
      // 根据类型确定数据长度（这里不直接返回长度，但可以根据需要添加）
      switch (type) {
        case 'B':
          result.length = BYTE_LENGTH.ONE; // 字节
          break;
        case 'W':
          result.length = BYTE_LENGTH.TWO; // 字（2字节）
          break;
        case 'D':
          result.length = BYTE_LENGTH.FOUR; // 双字（4字节）
          break;
      }
    } else {
      // 对于数据块数据格式，如果指定了位，则结果是布尔值
      result.length = BYTE_LENGTH.ZERO;
    }

    return result;
  }

  // 尝试匹配存储区格式（带位和不带位）
  let memoryMatch = memoryAreaRegexNoBit.exec(input) || memoryAreaRegexWithBit.exec(input);
  if (memoryMatch) {
    const [, , type] = memoryMatch;
    if (type && ['B', 'W', 'D'].includes(type)) {
      // 不带位的情况，根据类型确定数据长度（这里不直接返回长度，但可以根据需要添加）
      switch (type) {
        case 'B':
          result.length = BYTE_LENGTH.ONE; // 字节
          break;
        case 'W':
          result.length = BYTE_LENGTH.TWO; // 字（2字节）
          break;
        case 'D':
          result.length = BYTE_LENGTH.FOUR; // 双字（4字节）
          break;
      }
    } else {
      // 带位的情况
      // 对于存储区格式，如果指定了位，则结果是布尔值
      result.length = BYTE_LENGTH.ZERO;

    }
    return result;
  }

  return result;
}

export interface PointFormDialogProps {
  row?: FormModbusPoint;
  dataPropertiesAll?: DataProperty[];
  usedProperties?: number[];
  usedIdentifier?: string[];
  onCancel: () => void;
  onOk: (values: any) => void;
}

const PointFormDialog: React.FC<PointFormDialogProps> = ({
  row,
  dataPropertiesAll,
  usedProperties,
  usedIdentifier,
  onCancel,
  onOk,
}) => {
  const { setRow } = useContext(PointContext);
  const { dataProperties, dataPropertyId, setDataPropertyId, units, dataPropertyType } = useDataProperties(
    dataPropertiesAll,
    usedProperties
  );

  const [form] = Form.useForm();
  const tableRef = useRef<EditableTableRef | null>(null);

  const initStatusRef = useRef(true);
  const valueFilterMode = Form.useWatch('valueFilterMode', form);
  const address = Form.useWatch('address', form);
  const length = Form.useWatch<BYTE_LENGTH | undefined>('length', form);

  const initFormValues = useCallback(
    (
      length: BYTE_LENGTH | undefined,
      handleMode: number | undefined,
      valueType: VALUE_TYPE | undefined,
      address: string | undefined,
      enumMapping: object | undefined,
      isEnumMapping: boolean | undefined,
      rest: object = {}
    ) => {
      Promise.resolve()
        .then(() => {
          form.setFieldsValue(
            Object.assign(
              {
                ...rest,
              },
              !isNil(length) ? { length } : { length: parseDBString(address).length },
              !isNil(valueType) ? { valueType } : {},
              !isNil(handleMode) ? { handleMode } : {},
              !isNil(address) ? { address } : {},
              { IsEnumMapping: isEnumMapping },
              !isNil(enumMapping) ? { enumMapping } : {}
            )
          );
        })
        .finally(() => {
          initStatusRef.current = false;
        });
    },
    [form]
  );

  // 初始化
  useEffect(() => {
    if (row && !isNil(row.address)) {
      // 编辑 和 保存并继续添加
      const {
        handleMode,
        valueType,
        bitMode,
        bitNum,
        byteNum,
        address,
        enumMapping,
        length,
        isEnumMapping = !isNil(enumMapping),
        ...rest
      } = row;

      initFormValues(length, handleMode, valueType, address, enumMapping, isEnumMapping, rest);
    } else {
      // 新建
      const { handleMode, valueType } = getDefaultHandleModeAndValueType(undefined);
      initFormValues(undefined, handleMode, valueType, undefined, undefined, true, row);
    }
  }, [initFormValues, row]);


  const onValuesChange = (changedValues: { [key: string]: any }) => {
    const { address, length } = changedValues;

    // 根据输入的地址处理长度、处理方式和解码类型
    if (address) {
      // 如果用户输入的DB2,DBD5  这里面的，无论中英文，都自动转化为英文的.
      const newStarting = address.toUpperCase().replace(/，|,/g, '.');

      form.setFieldsValue({
        address: newStarting,
      });

      const { length } = parseDBString(newStarting);
      const { handleMode, valueType } = getDefaultHandleModeAndValueType(length);
      form.setFieldsValue({
        length,
        handleMode,
        valueType
      });
    }

    if(length) {
      form.setFieldsValue(getDefaultHandleModeAndValueType(length));
    }
  };

  useEffect(() => {
    if (row) {
      setDataPropertyId(row.dataPropertyId);
    }
    form.resetFields();
  }, [row]);

  useEffect(() => {
    if (dataPropertyType === DataPropertyType.YC && !isEmpty(units)) {
      form.setFieldsValue({
        physicalUnitId: units?.find(unit => unit.default)?.id,
        physicalUnitGeneralName: units?.find(unit => unit.default)?.generalName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPropertyId, dataPropertyType, units]);

  const submit = async (shouldContinue?: boolean) => {
    await tableRef.current?.validate?.();
    if (tableRef.current?.isValiDataSameValue !== undefined || tableRef.current?.isValidRequire !== undefined) {
      if (!tableRef.current?.isValidRequire || !tableRef.current?.isValiDataSameValue) {
        return;
      }
    }
    const values = await form.validateFields();
    const submitValues = {
      ...values,
      k: values.k ?? 1,
      b: values.b ?? 0,
      enumMapping: tableRef.current?.tempObj,
    };
    if (shouldContinue) {
      await onOk(submitValues);
      form.resetFields();
      setRow?.({
        ...values,
        pointType: DataPointType.BASE_POINT,
        dataPropertyId: undefined,
        physicalUnitId: undefined,
        identifier: undefined,
      });
    } else {
      await onOk(submitValues);
      onCancel();
    }
  };

  const IsEnumMapping = Form.useWatch('IsEnumMapping', form);

  return (
    <Modal
      width={600}
      open={row?.pointType === DataPointType.BASE_POINT}
      onCancel={onCancel}
      title={`${row?.id ? '编辑' : '添加'}采集点`}
      centered={true}
      maskClosable={false}
      footer={<FormSubmit submit={submit} cancel={onCancel} />}
    >
      <div style={{ height: 600, overflow: 'auto' }}>
        <Form {...formLayout} form={form} labelWrap={true} onValuesChange={onValuesChange}>
          <Form.Item noStyle name="dataPropertyName" />
          <Form.Item noStyle name="physicalUnitGeneralName" />
          <Form.Item
            name="hasProperty"
            label="采集点类型"
            initialValue={true}
            rules={[{ required: true, message: '请选择' }]}
            shouldUpdate
          >
            <Radio.Group disabled={!isNil(row?.id)}>
              <Radio value={true}>建模采集点</Radio>
              <Radio value={false}>非建模采集点</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            dependencies={['hasProperty']}
            shouldUpdate={(prevValues, curValues) => prevValues.hasProperty !== curValues.hasProperty}
          >
            {({ getFieldValue }) =>
              getFieldValue('hasProperty') ? (
                <>
                  <Form.Item
                    name="dataPropertyId"
                    label="数据属性"
                    rules={[{ required: true, message: '请选择数据属性' }]}
                  >
                    <Select
                      onChange={value => {
                        form.setFieldsValue({
                          dataPropertyName: dataProperties?.find(item => item.id === value)?.name,
                        });
                        setDataPropertyId(value as number);
                        form.setFieldsValue({
                          physicalUnitId: undefined,
                          physicalUnitGeneralName: '',
                        });
                      }}
                      placeholder="请输入/选择"
                      showSearch
                      filterOption={(input, option) =>
                        (option!.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                    >
                      {dataProperties?.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {dataPropertyType === DataPropertyType.YC && (
                    <Form.Item name="physicalUnitId" label="单位" rules={[{ required: true, message: '请选择单位' }]}>
                      <Select
                        onChange={value => {
                          form.setFieldsValue({
                            physicalUnitGeneralName: units?.find(item => item.id === value)?.generalName,
                          });
                        }}
                        placeholder="请选择"
                      >
                        {units?.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.generalName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </>
              ) : (
                <Form.Item
                  required
                  name="identifier"
                  label="非建模采集点标识符"
                  rules={[
                    {
                      validator: identifierValidatorBuilder(usedIdentifier),
                    },
                  ]}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
              )
            }
          </Form.Item>
          <Form.Item
            name="writable"
            label="是否可写"
            initialValue={false}
            rules={[{ required: true, message: '请选择' }]}
          >
            <Radio.Group>
              <Radio value={true}>是</Radio>
              <Radio value={false}>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            required
            name="address"
            label="地址"
            tooltip={{
              overlayStyle: { maxWidth: 560 },
              title: (
                <div style={{ color: 'rgba(255, 255, 255, 0.65)' }}>
                  <div style={{ color: 'var(--mx-warning-color)' }}>数据块数据格式（最常用）:</div>
                  <div>
                    <span style={{ color: 'rgba(255, 255, 255, 0.85)' }}>第一种：</span>DBx.DBXy.z =&gt;
                    x是DB块编号，y是字节，z是位 =&gt; 结果是布尔值
                    <br />
                    <span style={{ color: 'rgba(255, 255, 255, 0.85)' }}>第二种：</span>DBx.DBbc =&gt;
                    x是DB块编号，b是数据格式，c是数据位置, <br />
                    &nbsp;b为：B（字节）、W（字=2字节，一般存储短整数）、D（双字=4字节，可以存储整数或浮点数）
                    <br />
                    <span style={{ color: 'rgba(255, 255, 255, 0.85)' }}>示例：</span>
                    <br />
                    DB0.DBX0.0：数据块0第0字节0位
                    <br />
                    DB2.DBD4：数据块2第4双字（4字节）
                    <br />
                  </div>
                  <div style={{ color: 'var(--mx-warning-color)' }}>存储区格式（较少用）:</div>
                  <div>
                    <span style={{ color: 'rgba(255, 255, 255, 0.85)' }}>第一种：</span>xy.z =&gt; x是存储区格式，y是字节，z是位 ={'>'} 结果是布尔值
                    <br />
                    <span style={{ color: 'rgba(255, 255, 255, 0.85)' }}>第二种：</span>xbc ={'>'}{' '}
                    x是存储区格式，b是数据格式，c是数据位置
                    <br />
                    x为：I（输入区），Q（输出区），M（位存储区）
                    <br />
                    b为：B（字节）、W（字=2字节，一般存储短整数）、D（双字=4字节，可以存储整数或浮点数）
                    <br />
                    <span style={{ color: 'rgba(255, 255, 255, 0.85)' }}>示例：</span>
                    <br />
                    I0.0： 输入区0字节0位
                    <br />
                    MW100：位存储区第100字（2字节）
                    <br />
                  </div>
                </div>
              ),
            }}
            rules={[
              {
                validator: async (rule, value) => {
                  if (isNil(value)) {
                    return Promise.reject('请输入地址');
                  }
                  // 匹配数据块数据格式
                  const dbBlockRegex1 = /^DB(\d+)\.DB(B|D|W)(\d+)$/;
                  const dbBlockRegex2 = /^DB(\d+)\.DBX(\d+)(\.(\d+))$/;
                  // 匹配存储区格式（不带位）
                  const memoryAreaRegexNoBit = /^(I|Q|M)(B|W|D)?(\d+)$/;
                  // 匹配存储区格式（带位）
                  const memoryAreaRegexWithBit = /^(I|Q|M)(\d+)\.(\d+)$/;
                  if (
                    !dbBlockRegex1.test(`${value}`) &&
                    !dbBlockRegex2.test(`${value}`) &&
                    !memoryAreaRegexNoBit.test(`${value}`) &&
                    !memoryAreaRegexWithBit.test(`${value}`)
                  ) {
                    return Promise.reject(new Error('请输入正确的地址'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder="请输入" />
          </Form.Item>

          <Form.Item noStyle dependencies={['address']} shouldUpdate>
            {() => {
              return (
                <Form.Item name="length" label="字节长度" rules={[{ required: true, message: '请选择' }]}>
                  <Select
                    placeholder={address ? '请选择' : '请先输入地址'}
                    disabled={true}
                    getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                  >
                    <Option value={BYTE_LENGTH.ZERO}>{byteLengthFormatter[BYTE_LENGTH.ZERO]}</Option>
                    <Option value={BYTE_LENGTH.ONE}>{byteLengthFormatter[BYTE_LENGTH.ONE]}</Option>
                    <Option value={BYTE_LENGTH.TWO}>{byteLengthFormatter[BYTE_LENGTH.TWO]}</Option>
                    <Option value={BYTE_LENGTH.FOUR}>{byteLengthFormatter[BYTE_LENGTH.FOUR]}</Option>
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item noStyle dependencies={['length']} shouldUpdate>
            {() => {
              const formatter = getHandleModeMapping(length);

              return (
                length !== 0 &&
                length !== 1 && (
                  <Form.Item name="handleMode" label="处理方式" rules={[{ required: true, message: '请选择' }]}>
                    <Select
                      placeholder={address ? '请选择' : '请先输入地址'}
                      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                    >
                      {Object.entries(formatter || {}).map(([value, key]) => {
                        return (
                          <Option key={value} value={Number(value)}>
                            {key}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )
              );
            }}
          </Form.Item>
          <Form.Item noStyle dependencies={['length']} shouldUpdate>
            {() => {
              const formatter = getValueTypeMapping(length);
              return (
                <Form.Item name="valueType" label="解码类型" rules={[{ required: true, message: '请选择' }]}>
                  <Select
                    placeholder={address ? '请选择' : '请先输入地址'}
                    getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                  >
                    {Object.entries(formatter || {}).map(([value, key]) => {
                      return (
                        <Option key={value} value={Number(value)}>
                          {key}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Item>
          {/* 枚举值映射 */}
          {dataPropertyType === DataPropertyType.ENUM && (
            <>
              <Form.Item
                name="IsEnumMapping"
                label="枚举映射"
                rules={[{ required: true, message: '请选择是否枚举映射' }]}
                // initialValue={row?.enumMapping !== undefined ? (row?.enumMapping === null ? false : true) : true}
              >
                <Radio.Group>
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              </Form.Item>
              {/* 枚举映射框 */}
              <Form.Item noStyle dependencies={['IsEnumMapping', 'dataPropertyId']}>
                {({ getFieldValue }) => {
                  return (
                    getFieldValue('IsEnumMapping') === true && (
                      <Form.Item name="enumMapping" noStyle>
                        <EnumMapping ref={tableRef} dataProperties={dataProperties} />
                      </Form.Item>
                    )
                  );
                }}
              </Form.Item>
            </>
          )}

          {
            <Form.Item noStyle dependencies={['IsEnumMapping']} shouldUpdate>
              {({ getFieldValue }) => {
                return (
                  !IsEnumMapping && (
                    <>
                      <Form.Item
                        name="k"
                        label="乘积系数k"
                        tooltip="y=kx+b，x为直采数据，y为输出数据，k为乘积系数，b为偏移系数"
                        initialValue={1}
                        rules={[{ required: true, message: '请输入' }, { validator: checkNumCoefficient }]}
                      >
                        <Input placeholder="请输入-999999999~999999999的数字" />
                      </Form.Item>
                      <Form.Item
                        name="b"
                        label="偏移系数b"
                        tooltip="y=kx+b，x为直采数据，y为输出数据，k为乘积系数，b为偏移系数"
                        initialValue={0}
                        rules={[{ required: true, message: '请输入' }, { validator: checkNumCoefficient }]}
                      >
                        <Input placeholder="请输入-999999999~999999999的数字" />
                      </Form.Item>
                    </>
                  )
                );
              }}
            </Form.Item>
          }
          <Form.Item
            name="valueFilterMode"
            label="数值过滤模式"
            initialValue={0}
            rules={[{ required: true, message: '请选择' }]}
            shouldUpdate
          >
            <Radio.Group
              onChange={v => {
                if (v.target?.value === FilterMode.GROWTHREASONABLERANGE) {
                  message.warning('新网关版本在3.7.30版本以上');
                }
              }}
            >
              {Object.entries(FilterModeDisplay).map(([k, v]) => (
                <Radio key={k} value={Number(k)}>
                  {v}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          {valueFilterMode === 1 && (
            <Form.Item
              label="数值合理区间"
              name="valueRange"
              dependencies={['valueFloor', 'valueCeiling']}
              rules={[
                form => ({
                  validator: () => validatorValueRange(form),
                }),
              ]}
              tooltip="区间外数值将被直接滤除，视为无效数值"
            >
              <Row gutter={8}>
                <Col span={11}>
                  <Form.Item name="valueFloor" noStyle rules={[{ validator: checkNum }]}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={2}>--</Col>
                <Col span={11}>
                  <Form.Item name="valueCeiling" noStyle rules={[{ validator: checkNum }]}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          )}
          {valueFilterMode === 2 && (
            <>
              <Form.Item
                label="增长率合理区间"
                name="valueRange"
                required={true}
                dependencies={['valueFloor', 'valueCeiling']}
                rules={[
                  form => ({
                    validator: () => validatorValueRange(form),
                  }),
                ]}
                tooltip="新网关版本在3.7.30版本以上"
              >
                <Row gutter={8}>
                  <Col span={9}>
                    <Form.Item name="valueFloor" noStyle rules={[{ validator: checkNum }]}>
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={2}>--</Col>
                  <Col span={9}>
                    <Form.Item name="valueCeiling" noStyle rules={[{ validator: checkNum }]}>
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={3}>/min</Col>
                </Row>
              </Form.Item>
              <Form.Item
                name="valueFilterCnt"
                label="边缘侧异常数据过滤个数"
                initialValue={5}
                rules={[{ required: true, message: '请输入' }]}
              >
                <InputNumber
                  placeholder="请输入"
                  min={1}
                  max={20}
                  precision={0}
                  style={{
                    width: '100%',
                  }}
                />
              </Form.Item>
              <Form.Item
                label="云端增长率合理区间"
                name="cloudValueRange"
                dependencies={['cloudValueFloor', 'cloudValueCeiling']}
              >
                <Row gutter={8}>
                  <Col span={8}>
                    <Form.Item name="cloudValueFloor" noStyle rules={[{ validator: checkNum }]}>
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={2}>--</Col>
                  <Col span={8}>
                    <Form.Item name="cloudValueCeiling" noStyle rules={[{ validator: checkNum }]}>
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={5}>{units?.find(i => i.default)?.generalName ?? '-'}/min</Col>
                </Row>
              </Form.Item>
              <Form.Item name="cloudValueFilterCnt" label="云端异常数据过滤个数" initialValue={5}>
                <InputNumber
                  placeholder="请输入"
                  min={1}
                  max={1000}
                  precision={0}
                  style={{
                    width: '100%',
                  }}
                />
              </Form.Item>
            </>
          )}
          <Form.Item label="备注" name="remark" rules={[{ max: 50, message: '长度不能超过50个字符' }]}>
            <Input placeholder="请输入备注" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default PointFormDialog;
