/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint no-console: ["error", { allow: ["error"] }] */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, message, Row } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import FormContainer from '../components/FormContainer';
import styles from './index.module.scss';
import { Modal, Wrapper, useAsync } from '@maxtropy/components';
import {
  existOperationPassword,
  getCurrentUser,
  initOperationPassword,
  updateOperationPassword,
} from '../../../../api/cc-user';
import ResetPasswordModal, { formSubmitProps } from './ResetPasswordModal';
import { apiV2PswResetUpdatePswPost } from '@maxtropy/tody-mgmt-apis-v2';

const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

const operationEnum = ['init', 'update'];

const OperatePasswordForm: React.FC = props => {
  const [messageApi, messageContextHolder] = message.useMessage();
  const [current, setCurrent] = useState<string>('');
  const [resetPasswordVisible, setResetPasswordVisible] = useState<boolean>(false);

  const [initForm] = Form.useForm();
  const [updateForm] = Form.useForm();

  const user = useAsync(getCurrentUser);

  useEffect(() => {
    existOperationPassword({ corpUserId: user?.id }).then(res => {
      if (res) {
        setCurrent('update');
      } else {
        setCurrent('init');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkUpdateConfirm = (_: any, value: string) => {
    const promise = Promise;
    if (value && value !== updateForm.getFieldValue('newOperationPassword')) {
      return promise.reject('两次输入的密码不匹配');
    }
    return promise.resolve();
  };

  const checkInitConfirm = (_: any, value: string) => {
    const promise = Promise;
    if (value && value !== initForm.getFieldValue('operationPassword')) {
      return promise.reject('两次输入的密码不匹配');
    }
    return promise.resolve();
  };

  const handleUpdate = async (values: any) => {
    try {
      const res = await updateOperationPassword(values);
      if (res) {
        messageApi.success('操作密码修改成功！');
      } else {
        messageApi.error('操作密码修改失败！');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleInit = async (values: any) => {
    try {
      const res = await initOperationPassword(values);
      if (res) {
        messageApi.success('操作密码设置成功！');
      } else {
        messageApi.error('操作密码设置失败！');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onUpdateCancel = () => {
    updateForm.resetFields();
  };

  const onInitCancel = () => {
    initForm.resetFields();
  };

  const updateOperatePassword: React.ReactNode = (
    <Form {...formItemLayout} form={updateForm} onFinish={handleUpdate}>
      <FormItem
        name="oldOperationPassword"
        rules={[{ required: true, message: '请输入原操作密码' }]}
        label="原操作密码"
      >
        <Input.Password placeholder="请输入原操作密码" autoComplete="new-password" />
      </FormItem>
      <FormItem
        name="newOperationPassword"
        rules={[
          { required: true, message: '请输入新操作密码' },
          {
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\x20-\x7E]{8,}$/,
            message: '密码不少于8位，必须含大小写字母和数字',
          },
        ]}
        label="新操作密码"
      >
        <Input.Password placeholder="请输入至少8位数密码，密码必须含大小写字母和数字" autoComplete="new-password" />
      </FormItem>
      <FormItem
        name="validNewOperationPassword"
        rules={[{ required: true, message: '请确认新操作密码' }, { validator: checkUpdateConfirm }]}
        label="确认新操作密码"
      >
        <Input.Password placeholder="请输入至少8位数密码，密码必须含大小写字母和数字" autoComplete="new-password" />
      </FormItem>
      <Row>
        <Col span={5} />
        <Col span={12}>
          <ExclamationCircleOutlined className={styles.icon} />
          <Button type="link" onClick={() => setResetPasswordVisible(true)}>
            重置密码
          </Button>
        </Col>
      </Row>
      <Row className={styles.operateContainer}>
        <Col span={5} />
        <Col span={12}>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
          <Button onClick={onUpdateCancel}>取消</Button>
        </Col>
      </Row>
    </Form>
  );

  const initOperatePassword: React.ReactNode = (
    <Form {...formItemLayout} form={initForm} onFinish={handleInit}>
      <FormItem name="operationPassword" rules={[{ required: true, message: '请输入操作密码' }]} label="操作密码">
        <Input.Password placeholder="请输入操作密码" />
      </FormItem>
      <FormItem
        name="validateOperationPassword"
        rules={[{ required: true, message: '请确认操作密码' }, { validator: checkInitConfirm }]}
        label="确认操作密码"
      >
        <Input.Password placeholder="请确认操作密码" />
      </FormItem>
      <Row className={styles.operateContainer}>
        <Col span={5} />
        <Col span={12}>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
          <Button onClick={onInitCancel}>取消</Button>
        </Col>
      </Row>
    </Form>
  );

  const renderFormArray = [
    {
      title: '初始化操作密码',
      children: initOperatePassword,
    },
    {
      title: '修改操作密码',
      children: updateOperatePassword,
    },
  ];

  const routes = [
    { name: '个人中心' },
    { name: '个人设置' },
    { name: renderFormArray[operationEnum.indexOf(current)] && renderFormArray[operationEnum.indexOf(current)].title },
  ];

  const onSubmit = async (values: formSubmitProps) => {
    apiV2PswResetUpdatePswPost({ validCode: values.validCode, password: values.password }).then(res => {
      Modal.success({
        title: '提交成功',
        content: `操作密码已经重置为：${values.password}`,
      });
      setResetPasswordVisible(false);
    });
  };

  return (
    <Wrapper routes={routes}>
      {renderFormArray[operationEnum.indexOf(current)] && (
        <FormContainer
          title={renderFormArray[operationEnum.indexOf(current)].title}
          children={renderFormArray[operationEnum.indexOf(current)].children}
        />
      )}
      {messageContextHolder}
      <ResetPasswordModal
        visible={resetPasswordVisible}
        onCancel={() => setResetPasswordVisible(false)}
        onSubmit={onSubmit}
      />
    </Wrapper>
  );
};

export default OperatePasswordForm;
