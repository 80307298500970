import { EllipsisSpan } from '@maxtropy/components';
import { Input, Table, Tooltip } from 'antd';
import Form, { FormInstance, Rule } from 'antd/lib/form';
import React, { Ref, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { DataProperty } from '../../../../../types';
import { hex2int, map2Ary, map2Ary2, valiDataMust, valiDataSameValue } from '../utils';

interface EnumMappingProps {
  onChange?: (value: any) => void;
  value?: any;
  dataProperties?: any[];
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editable: boolean;
  dataIndex: string;
  title: any;
  record: any;
  index: number;
  rules?: Rule[];
  children: React.ReactNode;
  handleSave: (record: any) => void;
}

interface EditableRowProps {
  index: number;
  'data-row-key': number;
}

export type EditableTableRef = {
  validate: () => Promise<any>;
  tempObj: { [key: string]: any };
  isValidRequire: boolean;
  isValiDataSameValue: boolean;
};

const EditableRowContext = React.createContext<FormInstance<any> | null>(null);
const EditableContext = React.createContext<{ formInstanceMap: Map<number, FormInstance<any>> } | null>(null);

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();

  const { formInstanceMap } = useContext(EditableContext)!;

  useEffect(() => {
    formInstanceMap.set(props['data-row-key'], form);
    return () => {
      formInstanceMap.delete(props['data-row-key']);
    };
  }, []);

  return (
    <Form form={form} component={false}>
      <EditableRowContext.Provider value={form}>
        <tr {...props} />
      </EditableRowContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  dataIndex,
  children,
  rules,
  record,
  handleSave,
  ...restProps
}) => {
  const form = useContext(EditableRowContext)!;

  useEffect(() => {
    if (dataIndex && record) {
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    }
  }, [record, dataIndex]);

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = (
      <Form.Item style={{ margin: 0 }} name={dataIndex} rules={rules ?? []}>
        <Input placeholder="请输入值" onChange={save} onBlur={save} />
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EnumMapping = React.forwardRef((props: EnumMappingProps, ref: Ref<EditableTableRef>) => {
  const formInstanceMap = useRef<Map<number, FormInstance<any>>>(new Map());
  const form = Form.useFormInstance();
  const dataPropertyId = Form.useWatch('dataPropertyId', form);
  const { dataProperties, value } = props;
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [isValidRequire, setIsValidRequire] = useState<boolean>(true);
  const [isValiDataSameValue, setIsValiDataSameValue] = useState<boolean>(true);
  const tempObj = useRef<any>();
  const dataPropertyIdTemp = useRef<number>();

  useEffect(() => {
    return () => {
      setIsValiDataSameValue(true);
      setIsValidRequire(true);
    };
  }, []);

  useEffect(() => {
    setIsValiDataSameValue(true);
    if (dataPropertyId && dataProperties && dataProperties.length !== 0) {
      // 新增或者切类型的时候
      setDataSource([]);
      let findEnum = dataProperties.find(item => item.id === dataPropertyId);
      let map2Arylist = map2Ary(findEnum?.enumValue);
      // 当枚举值大于128时，不展示
      if (map2Arylist.length > 128) {
        setDataSource([]);
      } else {
        setDataSource(map2Arylist);
      }
      let valiDataMustRes = valiDataMust(map2Arylist);
      if (valiDataMustRes) {
        setIsValidRequire(true);
      } else {
        setIsValidRequire(false);
      }
      // 有值编辑回显的时候
      if (
        value &&
        JSON.stringify(value) !== '{}' &&
        (dataPropertyId === dataPropertyIdTemp.current || dataPropertyIdTemp.current === undefined)
      ) {
        tempObj.current = value;
        let list = map2Ary2(value);
        map2Arylist.forEach(item => {
          let find = list.find(i => String(i.enum) === String(item.enum));
          if (find) {
            item.value = Number(find.value).toString(16);
          }
        });
        setDataSource(map2Arylist);
        let valiDataMustRes = valiDataMust(map2Arylist);
        if (valiDataMustRes) {
          setIsValidRequire(true);
        } else {
          setIsValidRequire(false);
        }
      }
      dataPropertyIdTemp.current = dataPropertyId;
    }
  }, [dataProperties, dataPropertyId, value]);

  const columns = [
    {
      title: '枚举',
      dataIndex: 'enum',
      ellipsis: { showTitle: true },
      width: 130,
      render: (v: any) => <EllipsisSpan value={v ?? '--'} />,
    },
    {
      title: '枚举值名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      width: 130,
      render: (v: any) => <EllipsisSpan value={v ?? '--'} />,
    },
    {
      title: (
        <div>
          <span> 采集（原始）值</span>
          <br />
          <Tooltip
            title={
              !isValidRequire ? '必须填写一个采集（原始）值' : !isValiDataSameValue ? '采集（原始）值不能有重复' : ''
            }
          >
            <span style={{ color: 'red' }}>
              {!isValidRequire ? '必须填写一个采集（原始）值' : !isValiDataSameValue ? '采集（原始）值不能有重复' : ''}
            </span>
          </Tooltip>
        </div>
      ),
      dataIndex: 'value',
      ellipsis: { showTitle: true },
      width: 130,
      editable: true,
      render: (v: any) => <EllipsisSpan value={v} />,
      rules: [{ pattern: /^[A-Fa-f0-9]{1,4}$/, message: '请输入16进制数' }],
    },
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataProperty) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editable: col.editable,
        rules: col.rules,
        handleSave,
      }),
    };
  });

  useImperativeHandle(ref, () => ({
    validate: () => Promise.all(Array.from(formInstanceMap.current.values()).map(form => form.validateFields())),
    tempObj: tempObj.current,
    isValidRequire,
    isValiDataSameValue,
  }));

  // 输入框输入时，保存值并替换原有的值
  const handleSave = (row: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => row.enum === item.enum);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    let obj: { [key: string]: any } = {};
    newData.forEach((item: { value: string; enum: string }) => {
      if (item.value) {
        let key = hex2int(item.value);
        obj[key] = item.enum;
      }
    });
    tempObj.current = obj;
    // 校验至少有一个必填
    let valiData = JSON.parse(JSON.stringify(newData));
    let valiDataMustRes = valiDataMust(valiData);
    // 校验是否有重复值
    let valiDataSameValueRes = valiDataSameValue(valiData, 'value');
    // console.log("valiData",valiData)
    // console.log("valiDataSameValueRes",valiDataSameValueRes)
    if (valiDataMustRes) {
      setIsValidRequire(true);
    } else {
      setIsValidRequire(false);
    }
    if (valiDataSameValueRes) {
      setIsValiDataSameValue(true);
    } else {
      setIsValiDataSameValue(false);
    }
  };

  return (
    <EditableContext.Provider value={{ formInstanceMap: formInstanceMap.current }}>
      <Table
        style={{ marginBottom: '10px' }}
        scroll={{ y: 240 }}
        components={{
          body: {
            cell: EditableCell,
            row: EditableRow,
          },
        }}
        rowClassName="editable-row"
        rowKey="enum"
        key={'enum'}
        dataSource={dataSource}
        // @ts-ignore
        columns={[...mergedColumns]}
        pagination={false}
      />
    </EditableContext.Provider>
  );
});

export default EnumMapping;
