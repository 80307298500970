import * as React from 'react';
import { Form, Row, Col, Modal, Space, message, Button } from 'antd';
import FormText from '../common/FormText';
import { DetailsProps } from '../factory';
import TemplateModal, { TemplateModalRef } from './TemplateModal';
import { useEffect, useState } from 'react';
import TemplateVersionModal, { TemplateVersionModalRef } from './TemplateVersionModal';
import { MutableRefObject } from 'react';
import { getEdgeTemplateDetail } from '../../../api/template';
import { IotProtocolTypeDisplay } from '@/shared/types';
import styles from '../index.module.scss';
import { EstunFormValues } from '../index';
import { halfFormItemLayout } from '@/shared/components/DataAcquisitionForm/common';


const EstunDetails: React.FC<DetailsProps<EstunFormValues>> = ({ form, state }) => {
  const templateListModalRef: MutableRefObject<TemplateModalRef | null> = React.useRef(null);
  const [templateListVisible, setTemplateListVisible] = useState<boolean>(false);
  const { setTemplateVersionId, templateVersionId } = state;
  const [messageApi, messageContextHolder] = message.useMessage();

  const hideTemplateListModal = () => setTemplateListVisible(false);

  const handleTemplateListModal = async () => {
    const templateVersionId = templateListModalRef?.current?.selectedRowKeys;
    if (templateVersionId) {
      setTemplateVersionId(templateVersionId);
    }
    setTemplateListVisible(false);
  };

  useEffect(() => {
    if (templateVersionId) {
      getEdgeTemplateDetail(+templateVersionId).then(detail => {
        form.setFieldsValue({ ...detail, iotProtocol: IotProtocolTypeDisplay[detail.iotProtocol] });
      });
    }
  }, [templateVersionId, form]);

  const versionListModalRef: MutableRefObject<TemplateVersionModalRef | null> = React.useRef(null);
  const [versionListVisible, setVersionListVisible] = useState<boolean>(false);

  const hideVersionListModal = () => setVersionListVisible(false);
  const handleVersionListModal = () => {
    const templateVersionId = versionListModalRef?.current?.selectedRowKeys;
    if (templateVersionId) {
      setTemplateVersionId(templateVersionId);
    }
    setVersionListVisible(false);
  };

  return (
    <div className={styles.estunDetails}>
      <Form.Item {...halfFormItemLayout} required label={'数采模板'}>
        <Space>
          <Form.Item name={'name'} noStyle rules={[{ required: true, message: '请选择数采模板' }]}>
            {templateVersionId && <FormText />}
          </Form.Item>
          <Button
            type="link"
            onClick={() => (state.deviceType ? setTemplateListVisible(true) : messageApi.info('请选择所属类目'))}
          >
            {templateVersionId ? '重新选择' : '请选择'}
          </Button>
        </Space>
      </Form.Item>
      {templateVersionId ? (
        <Row>
          <Col span={12}>
            <Form.Item {...halfFormItemLayout} label={'模板版本号'}>
              <Space>
                <Form.Item name={'version'} noStyle rules={[{ required: true, message: '请选择模板版本号' }]}>
                  <FormText className={styles.version} />
                </Form.Item>
                <Button type="link" onClick={() => setVersionListVisible(true)}>
                  重新选择
                </Button>
              </Space>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item {...halfFormItemLayout} name={'versionRemark'} label={'版本备注'}>
              <FormText />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item {...halfFormItemLayout} name={'deviceTypeName'} label={'所属类目'}>
              <FormText />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item {...halfFormItemLayout} name={'deviceModelName'} label={'所属型号'}>
              <FormText />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item {...halfFormItemLayout} name={'iotProtocol'} label={'物联层协议'}>
              <FormText />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item {...halfFormItemLayout} name={'remark'} label={'模板备注'}>
              <FormText />
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <div />
      )}
      <Modal
        wrapClassName={styles.customModal}
        width={1000}
        title="模板选择"
        open={templateListVisible}
        destroyOnClose
        onOk={handleTemplateListModal}
        onCancel={hideTemplateListModal}
      >
        <TemplateModal state={state} ref={templateListModalRef} />
      </Modal>
      <Modal
        wrapClassName={styles.customModal}
        width={1000}
        title="版本选择"
        open={versionListVisible}
        destroyOnClose
        onOk={handleVersionListModal}
        onCancel={hideVersionListModal}
      >
        <TemplateVersionModal state={state} ref={versionListModalRef} />
      </Modal>
      {messageContextHolder}
    </div>
  );
};

export default EstunDetails;
